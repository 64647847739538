// TODO: consolidate the progress drawers, especially this and the hierarchy node left side one
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Project } from '../../../@types/api/v0/rest/Project';
import { ProgressTrackingFloorplanProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { UserManagementApi } from '../../../api/next';
import { ProgressTrackingApi } from '../../../api/v1/bespoke/ProgressTrackingApi';
import { ProgressTrackingQueryKeys, QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import { FloorplanProgressDrawer } from './FloorplanProgressDrawer';

export interface FloorplanProgressDrawerContainerProps {
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current project. */
  project: Project;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** The floorplan to display in the drawer. */
  floorplan?: Floorplan;
  /** Flag indicating whether or not the floorplan has momentum. */
  hasMomentum?: boolean | null;
}

const FloorplanProgressDrawerContainer = (props: FloorplanProgressDrawerContainerProps) => {
  const { isOpen, onClose, project, selectedDate, floorplan, hasMomentum } = props;

  const location: Floorplan | undefined = floorplan;
  const locationType = 'FLOORPLAN_TOTALS';

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const queryKey = [
    QueryTopics.PROGRESS_TRACKING,
    ProgressTrackingQueryKeys.PROGRESS_HISTORY,
    project.id,
    locationType,
    location?.id,
    selectedDate,
  ];
  const progressHistoryQuery = useQuery({
    enabled: Boolean(isOpen),
    queryKey,
    queryFn: async ({ signal }) => {
      const promise: Promise<AxiosResponse<ProgressTrackingFloorplanProgressHistory>> =
        ProgressTrackingApi.getTotalFloorplanProgressHistory(floorplan?.id ?? -1, {
          signal,
        });

      return (await promise).data;
    },
  });

  const canEditMilestoneCompletionDate = (meQuery.data?.set_trade_dates ?? []).some(
    (account) => account.id === project.account.id
  );

  const isProgressTrackingEnabled = project.executive_dashboard_enabled;

  const progressValue =
    progressHistoryQuery.data?.progress_values && progressHistoryQuery.data.progress_values.length > 0
      ? progressHistoryQuery.data?.progress_values[progressHistoryQuery.data.progress_values.length - 1].value
      : undefined;

  return (
    <FloorplanProgressDrawer
      canEditDates={canEditMilestoneCompletionDate}
      isProgressTrackingEnabled={isProgressTrackingEnabled}
      hasMomentum={hasMomentum}
      isError={progressHistoryQuery.isError}
      isFetching={progressHistoryQuery.isFetching}
      isOpen={Boolean(isOpen)}
      progressHistory={progressHistoryQuery.data}
      onClose={onClose}
      selectedDate={selectedDate}
      progressValue={progressValue}
      floorplan={floorplan}
      projectId={project.id}
    />
  );
};

export default FloorplanProgressDrawerContainer;
