import { useQuery } from '@tanstack/react-query';

import { UserManagementApi } from '../api/next';
import { QueryTopics, UserManagementQueryKeys } from '../constants/queries';

/**
 * Hook for firing off a User Management permissions (i.e. "Me") query when authenticated and exposing its result and
 * computed values.
 *
 * @deprecated Just add a `meQuery` where needed. This hook adds an extra layer of indirection, difficulty when writing
 * tests because this leads to the developer needing to mock more things, and can be confusing.
 */
function usePermissions() {
  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const accountsWithAccessAllProjectPermission = meQuery.data?.access_account_projects ?? [];
  const accountsWithInvitePermission = meQuery.data?.invite_to_account_projects ?? [];
  const accountsWithManagePermission = meQuery.data?.manage_users ?? [];
  const canInviteUsers = (meQuery.data?.invite_to_account_projects ?? []).length > 0;
  const accountsWithOneOrMoreProject = meQuery.data?.access_account_projects_subset ?? [];
  const accountsWithSetDatesPermission = meQuery.data?.set_trade_dates ?? [];

  return {
    accountsWithAccessAllProjectPermission,
    accountsWithInvitePermission,
    accountsWithManagePermission,
    canInviteUsers,
    accountsWithOneOrMoreProject,
    accountsWithSetDatesPermission,
    meQuery,
  };
}

export default usePermissions;
