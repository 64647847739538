import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const xs = defineStyle({
  fontSize: '0.75rem',
  letterSpacing: '0.1px',
  lineHeight: 1,
});

const sm = defineStyle({
  fontSize: '0.875rem',
  letterSpacing: '0.1px',
  lineHeight: 1.286,
});

const md = defineStyle({
  fontSize: '1rem',
  letterSpacing: '0.1px',
  lineHeight: 1.375,
});

const lg = defineStyle({
  fontSize: '1.125rem',
  letterSpacing: '0.1px',
  lineHeight: 1.889,
});

// Variant for usage within our custom List component
const list = defineStyle({
  flex: 1,
  borderTop: '1px solid var(--gray-100)',
  fontWeight: 400,
  padding: '0.75rem 1.5rem',
  backgroundColor: 'var(--white)',

  _first: {
    borderRight: '1px solid var(--gray-100)',
    borderRadius: '0 0 0 0.5rem',
  },

  _last: {
    borderRadius: '0 0 0.5rem 0',
  },

  _hover: {
    backgroundColor: 'var(--gray-100)',
  },
});

const highEmphasisV2 = defineStyle({
  /** Generic button styles which should be moved to the future base button style */
  borderRadius: 'var(--rounding-large-button)',
  fontWeight: 400,
  height: 'auto',
  /** End generic button styles */

  backgroundColor: 'var(--primary-500)',
  border: '0.0625rem solid var(--primary-500)',
  color: 'var(--white)',
  padding: '0.375rem 0.9375rem',

  _hover: {
    _disabled: {
      backgroundColor: 'var(--primary-disabled)',
      borderColor: 'var(--primary-disabled)',
    },
    backgroundColor: 'var(--primary-800)',
    borderColor: 'var(--primary-800)',
  },

  _disabled: {
    backgroundColor: 'var(--primary-disabled)',
    borderColor: 'var(--primary-disabled)',
    opacity: 1,
  },
});

const mediumEmphasisV2 = defineStyle({
  /** Generic button styles which should be moved to the future base button style */
  borderRadius: 'var(--rounding-large-button)',
  fontWeight: 400,
  height: 'auto',
  /** End generic button styles */

  border: '0.0625rem solid var(--primary-500)',
  color: 'var(--primary-500)',
  padding: '0.375rem 0.9375rem',

  _hover: {
    _disabled: {
      borderColor: 'var(--primary-200)',
      color: 'var(--primary-200)',
    },
    borderColor: 'var(--primary-700)',
    color: 'var(--primary-700)',
  },

  _disabled: {
    borderColor: 'var(--primary-200)',
    color: 'var(--primary-200)',
    opacity: 1,
  },
});

const lowEmphasisV2 = defineStyle({
  /** Generic button styles which should be moved to the future base button style */
  borderRadius: 'var(--rounding-large-button)',
  fontWeight: 400,
  height: 'auto',
  /** End generic button styles */

  border: '0.0625rem solid var(--gray-600)',
  color: 'var(--gray-600)',
  padding: '0.375rem 0.9375rem',

  _hover: {
    _disabled: {
      borderColor: 'var(--gray-400)',
      color: 'var(--gray-400)',
    },
    borderColor: 'var(--gray-900)',
    color: 'var(--gray-900)',
  },

  _disabled: {
    borderColor: 'var(--gray-400)',
    color: 'var(--gray-400)',
    opacity: 1,
  },
});

const roundPrimary = defineStyle({
  /** Generic button styles which should be moved to the future base button style */
  fontSize: '0.875rem',
  fontWeight: 400,
  letterSpacing: '0.1px',
  /** End generic button styles */

  borderRadius: '1rem',
  height: '1.5rem',
  minWidth: '1.5rem',
  padding: '0.25rem 0.5rem',

  _active: {
    color: 'var(--white)',
    backgroundColor: 'var(--primary-400)',
  },

  _hover: {
    _disabled: {
      backgroundColor: 'var(--primary-disabled)',
    },
    backgroundColor: 'var(--primary-100)',
    color: 'var(--primary-400)',
  },
});

const ghost = defineStyle({
  _hover: {
    bg: 'var(--gray-100)',
  },
  _active: {
    bg: 'var(--gray-100)',
  },
});

const sidebar = defineStyle({
  _hover: {
    bg: 'var(--gray-700)',
    color: 'var(--white)',
  },
  _active: {
    color: 'var(--white)',
  },
});

const outline = defineStyle({
  /** Generic button styles which should be moved to the future base button style */
  fontWeight: 400,
  height: 'auto',
  letterSpacing: '0.1px',
  /** End generic button styles */

  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--gray-200)',
  borderRadius: '0.5rem',
  color: 'var(--gray-800) !important',
  minHeight: '2.875rem',

  _active: {
    backgroundColor: 'var(--gray-50)',
    borderColor: 'var(--primary-500)',
  },

  _hover: {
    _disabled: {
      borderColor: 'var(--gray-400)',
      color: 'var(--gray-400)',
    },
    backgroundColor: 'transparent',
    borderColor: 'var(--gray-500)',
  },

  _disabled: {
    borderColor: 'var(--gray-200) !important',
    color: 'var(--gray-400) !important',
    opacity: 1,
  },
});

const primaryExternal = defineStyle({
  /** Generic button styles which should be moved to the future base button style */
  fontWeight: 400,
  height: 'auto',
  letterSpacing: '0.1px',
  /** End generic button styles */

  backgroundColor: 'var(--primary-400)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--primary-400)',
  borderRadius: '0.5rem',
  color: 'var(--white) !important',
  lineHeight: 1,
  minHeight: '2.875rem',
  minWidth: 'fit-content',

  _active: {
    backgroundColor: 'var(--primary-200)',
    borderColor: 'var(--primary-200)',
  },

  _disabled: {
    backgroundColor: 'var(--primary-200)',
    borderColor: 'var(--primary-200)',
  },

  _hover: {
    backgroundColor: 'var(--primary-300)',
    borderColor: 'var(--primary-300)',
  },
});

const view360 = defineStyle({
  /** Generic button styles which should be moved to the future base button style */
  fontWeight: 400,
  height: 'auto',
  letterSpacing: '0.1px',
  /** End generic button styles */

  backgroundColor: 'var(--white)',
  borderRadius: '0.25rem',
  color: 'var(--gray-400)',
  lineHeight: 1,
  padding: '0.25rem',

  _active: {
    backgroundColor: 'var(--gray-800)',
    color: 'var(--white)',
  },

  _disabled: {
    color: 'var(--gray-200)',
    opacity: 1,

    _hover: {
      backgroundColor: 'var(--white) !important',
      color: 'var(--gray-200)',
      opacity: 1,
    },
  },

  _hover: {
    backgroundColor: 'var(--white)',
    color: 'var(--gray-900)',
  },
});

const drawerArrows = defineStyle({
  bg: (theme) => theme.colors.brand.primary[400],
  borderRadius: '0.5rem 0 0 0.5rem',
  boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
  color: (theme) => theme.colors.white,
  height: 'fit-content !important',
  minWidth: 0,
  paddingBlock: '1rem !important',
  paddingInline: '0.25rem !important',
  _hover: {
    bg: (theme) => theme.colors.brand.primary[800],
  },
});

export const buttonTheme = defineStyleConfig({
  sizes: { xs, sm, md, lg },
  variants: {
    drawerArrows,
    ghost,
    highEmphasisV2,
    list,
    lowEmphasisV2,
    mediumEmphasisV2,
    outline,
    primaryExternal,
    roundPrimary,
    sidebar,
    view360,
  },
});
