import { Flex, Icon, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UserProfile } from '../@types/api/v0/rest/UserProfile';
import NylasIntegrationApi from '../api/v1/integration/NylasIntegrationApi';
import { ErrorIcon } from '../components/Icon';
import { InternalLayout } from '../components/Layout';
import Content from '../components/Layout/Content';
import LoadingIndicator from '../components/LoadingIndicator';
import { AuthQueryKeys, NylasIntegrationQueryKeys, QueryTopics } from '../constants/queries';
import { LocalStorageKeys } from '../constants/storage';
import theme from '../theme';

/**
 * Redirect container page rendered when the user is brought back to the platform from the selected email provider's
 * authentication flow. The URL should include a query parameter called `code`. When passed to Core API, it will be
 * exchanged for a Nylas grant ID. The grant ID is never shared with the FE, but is critical for email ingestion.
 *
 * The authentication flow begins by selecting a provider, retrieving a redirect URL from Core API, then opening it in a
 * new tab. After successful authentication, the provider redirects the user back to this component's mapped URL. The
 * original tab is still open. For this reason, this component is responsible for:
 *
 * 1. Sending the code up to Core API.
 * 2. Setting a value in `localStorage` used to inform the other tab that the authentication flow was successful.
 * 3. Closing the tab.
 *
 * In the event that the tab does not close as expected, a message is shown to the user. The user's profile is also
 * updated in case the user continues to use the tab.
 */
const NylasCallbackRedirectContainer = () => {
  const location = useLocation<{ code?: string }>();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  const [isCompleteMessageVisible, setIsCompleteMessageVisible] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const submitAuthenticationCodeMutation = useMutation({
    mutationKey: [QueryTopics.NYLAS_INTEGRATION, NylasIntegrationQueryKeys.AUTHENTICATION_CODE],
    mutationFn: ({ code }: { code: string }) => NylasIntegrationApi.submitAuthenticationCode(code),
    onError: (error) => {
      console.error('[NylasCallbackRedirectContainer] Failed to submit provider authentication code', error);
    },
    onSuccess: () => {
      // Update user profile: manually set `is_nylas_integrated` to `true`.
      const queryKey = [QueryTopics.AUTH, AuthQueryKeys.USER_PROFILE];
      const nextUserProfile = { ...queryClient.getQueryData<UserProfile>(queryKey), is_nylas_integrated: true };
      queryClient.setQueryData(queryKey, nextUserProfile);

      // Local storage values must be strings, so we purposely stringify `true` here.
      localStorage.setItem(LocalStorageKeys.NylasOAuthFlowComplete, 'true');

      // In the `NylasAuthenticationModalContainer`, we open a tab programmatically. Technically, only windows opened
      // with `window.open()` may be closed this way. This should work, but if it fails, show a fallback message.
      window.close();
      setIsCompleteMessageVisible(true);
    },
  });

  useEffect(() => {
    if (!code || isCompleteMessageVisible || !submitAuthenticationCodeMutation.isIdle) {
      return;
    }

    submitAuthenticationCodeMutation.mutate({ code });
  }, [code, isCompleteMessageVisible, submitAuthenticationCodeMutation]);

  if (!code) {
    return (
      <InternalLayout>
        <Content constrainToPageHeight>
          <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
            <Icon aria-hidden as={ErrorIcon} marginBottom="0.625rem" height="3.5rem" width="3.5rem" />
            <Text color={theme.colors.brand.gray[900]} textAlign="center">
              No provider authentication code present. Please try again later. If this issue persists, contact{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
            </Text>
          </Flex>
        </Content>
      </InternalLayout>
    );
  }

  if (submitAuthenticationCodeMutation.isError) {
    return (
      <InternalLayout>
        <Content constrainToPageHeight>
          <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
            <Icon aria-hidden as={ErrorIcon} marginBottom="0.625rem" height="3.5rem" width="3.5rem" />
            <Text color={theme.colors.brand.gray[900]} textAlign="center">
              Failed to complete provider authentication process. Please try again later. If this issue persists,
              contact <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
            </Text>
          </Flex>
        </Content>
      </InternalLayout>
    );
  }

  if (isCompleteMessageVisible) {
    return (
      <InternalLayout>
        <Content constrainToPageHeight>
          <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
            <Text textAlign="center">
              Successfully authenticated with provider. This tab should close automatically. If you see this for more
              than a few seconds, please close this tab.
            </Text>
          </Flex>
        </Content>
      </InternalLayout>
    );
  }

  return (
    <InternalLayout>
      <LoadingIndicator fullPage />
    </InternalLayout>
  );
};

export default NylasCallbackRedirectContainer;
