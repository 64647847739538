// TODO: enum for cookie names.

/**
 * Keys for local storage entries. Note that each of these begin with `onsitevision_` to avoid conflicts with
 * third-party code.
 */
export enum LocalStorageKeys {
  NylasOAuthFlowComplete = 'onsitevision_nylas_oauth_flow_complete',
  ProcoreOAuthFlowComplete = 'onsitevision_procore_oauth_flow_complete',
}
