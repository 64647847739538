import { ComponentWithAs, Flex, Icon, IconProps, Text } from '@chakra-ui/react';

import theme from '../../theme';
import { CalendarWarningIcon, CheckIcon, OnTrackIcon, WarningIcon } from '../Icon';

export interface MomentumReasonProps {
  /** Whether or not there is a completion date. */
  hasCompletionDate: boolean;
  /** Whether or not there is momentum. */
  hasMomentum: boolean;
}

type CompletionDateOption = 'hasDate' | 'noDate';

type MomentumOption = 'hasMomentum' | 'noMomentum';

interface TextWithIcon {
  text: string;
  icon: ComponentWithAs<'svg', IconProps>;
}

export const MomentumReason = ({ hasCompletionDate, hasMomentum }: MomentumReasonProps) => {
  const reasonMap: Record<CompletionDateOption, Record<MomentumOption, TextWithIcon>> = {
    hasDate: {
      hasMomentum: { text: 'On-Track', icon: OnTrackIcon },
      noMomentum: { text: 'Behind Schedule', icon: CalendarWarningIcon },
    },
    noDate: {
      hasMomentum: { text: 'Active', icon: CheckIcon },
      noMomentum: { text: 'Stalled', icon: WarningIcon },
    },
  };

  const { text, icon } =
    reasonMap[hasCompletionDate ? 'hasDate' : 'noDate'][hasMomentum ? 'hasMomentum' : 'noMomentum'];

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap="0.5rem"
      backgroundColor={theme.colors.brand.gray[50]}
      padding="0.5rem 1rem"
      borderRadius="1rem"
      width="fit-content"
    >
      <Icon aria-hidden as={icon} height="1.125rem" width="1.125rem" />
      <Text color={theme.colors.brand.gray[600]}>{text}</Text>
    </Flex>
  );
};
