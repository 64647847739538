import { Box, Button, DrawerProps, Flex, Heading, Icon, StatGroup, Text } from '@chakra-ui/react';
import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import {
  PortfolioProject,
  PortfolioProjectMilestoneProgress,
  PortfolioProjectProgressHistory,
} from '../../@types/api/v1/bespoke/PortfolioDashboard';
import { TargetWalkthroughResponse } from '../../api/v1/bespoke/ProjectTo360Api';
import { ProjectBaselineDatesContainer } from '../../components/BaselineDates/ProjectBaselineDatesContainer';
import { Drawer } from '../../components/Drawer/Drawer';
import { Degree360Icon } from '../../components/Icon';
import { MomentumReason } from '../../components/ProgressChart/MomentumReason';
import { ProgressChart } from '../../components/ProgressChart/ProgressChart';
import { DurationStat } from '../../components/Statistics/DurationStat';
import { EstimatedProgress } from '../../components/Statistics/EstimatedProgress';
import { PercentChangeStat } from '../../components/Statistics/PercentChangeStat';
import { DrawerType, PendoTopic } from '../../constants/analytics';
import theme from '../../theme';
import { generateProgressTrackingPageUrl, generateProjectPageUrl } from '../../utils/navigationUtils';
import { formatAddress } from '../../utils/stringUtils';
import { MilestoneActivity } from './MilestoneActivity';

export type ProjectDrawerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  project: PortfolioProject;
  progressHistory?: PortfolioProjectProgressHistory;
  milestoneProgress?: PortfolioProjectMilestoneProgress;
  isFetching?: boolean;
  isError: boolean;
  targetWalkthroughInfo?: TargetWalkthroughResponse;
};

export const ProjectDrawer = ({
  isOpen,
  onClose,
  project,
  progressHistory,
  milestoneProgress,
  isFetching,
  isError,
  targetWalkthroughInfo,
}: ProjectDrawerProps) => {
  const history = useHistory();

  const onViewProgress = (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (event.ctrlKey || event.metaKey) {
      return;
    }
    event.preventDefault();
    const progressTrackingPath = generateProgressTrackingPageUrl(project.id);
    history.push(progressTrackingPath);
  };

  const errorContent = (
    <Text>
      An error occurred while loading this project's progress history. Please try again later. If this issue persists,
      contact <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
    </Text>
  );

  const isProgressTrackingEnabled = project.progress_tracking_enabled;

  const completionDate = progressHistory?.baseline_completion_date
    ? new Date(`${progressHistory.baseline_completion_date} 12:00:00`)
    : null;

  const startDate = progressHistory?.start_date ? new Date(`${progressHistory?.start_date} 12:00:00`) : null;

  const bodyContent = (
    <>
      <Box>
        <EstimatedProgress
          onViewProgress={onViewProgress}
          progress={isProgressTrackingEnabled ? project.total_progress : null}
          progressBarSize="lg"
          progressTrackingUrl={generateProgressTrackingPageUrl(project.id)}
        />
        <StatGroup marginTop="1.5rem">
          <PercentChangeStat value={isProgressTrackingEnabled ? project.percent_change : null} />
          <DurationStat daysElapsed={isProgressTrackingEnabled ? progressHistory?.days_elapsed : 0} />
        </StatGroup>
        <ProjectBaselineDatesContainer completionDate={completionDate} project={project} startDate={startDate} />
      </Box>
      <Flex
        borderBottom={`1px solid ${theme.colors.brand.gray[200]}`}
        marginBlockStart="1rem"
        paddingBottom="1.5rem"
        direction="column"
        alignItems="center"
      >
        <ProgressChart
          baselineCompletionDate={completionDate?.getTime()}
          baselineStartDate={startDate?.getTime()}
          data={
            isProgressTrackingEnabled
              ? progressHistory?.progress_values.map(({ date, value }) => ({
                  date: new Date(`${date} 12:00:00`).getTime(),
                  value,
                })) ?? []
              : []
          }
          highlightMode="duration"
          highlightInterval={30}
          minDurationToHighlight={30}
          hasMomentum={project.momentum}
          minHeight="15rem"
        />
        {project.momentum !== null && (
          <MomentumReason hasMomentum={project.momentum} hasCompletionDate={Boolean(completionDate)} />
        )}
      </Flex>
      {milestoneProgress && (
        <Box padding="1rem 0">
          <Heading size="lg" as="h2" color={theme.colors.brand.gray[600]} fontWeight={400} marginBottom="1rem">
            Milestone Activity
          </Heading>
          <MilestoneActivity
            active={isProgressTrackingEnabled ? milestoneProgress.active : []}
            started={isProgressTrackingEnabled ? milestoneProgress.started : []}
            completed={isProgressTrackingEnabled ? milestoneProgress.completed : []}
          />
        </Box>
      )}
    </>
  );

  let goTo360Button;
  if (targetWalkthroughInfo?.walkthrough_id && targetWalkthroughInfo.floorplan_id) {
    const url = generateProjectPageUrl(
      project.id,
      targetWalkthroughInfo.floorplan_id,
      targetWalkthroughInfo.walkthrough_id
    );
    goTo360Button = (
      <Button
        as="a"
        data-pendo-label="Click view 360 from project drawer"
        data-pendo-topic={PendoTopic.PORTFOLIO}
        flex="none"
        href={url}
        leftIcon={<Icon as={Degree360Icon} height="1.25rem" width="1.25rem" />}
        onClick={(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
          if (event.ctrlKey || event.metaKey) {
            return;
          }
          event.preventDefault();
          history.push(url);
        }}
        size="sm"
        variant="mediumEmphasisV2"
      >
        View 360
      </Button>
    );
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      pendoTopic={PendoTopic.PORTFOLIO}
      drawerType={DrawerType.PROJECT}
      primaryText={project.name}
      secondaryText={formatAddress(project.address_line, project.city, project.state, project.zip_code)}
      isFetching={isFetching}
      isError={isError}
      errorContent={errorContent}
      bodyContent={bodyContent}
      goTo360Button={goTo360Button}
    />
  );
};
