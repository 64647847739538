import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Store } from '../../@types/redux/store';
import { login } from '../../actions/auth';
import api from '../../api';
import Toast from '../../components/Toast';
import theme from '../../theme';
import { transformErrorResponse } from '../../utils/error';
import ChangePasswordModal from './ChangePasswordModal';

interface FormValues {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone?: string;
}

// Note that this schema varies slightly from the onboarding registration schema.
const validationSchema = Yup.object({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  title: Yup.string().required('Title is required'),
  email: Yup.string().email('Email address invalid').required('Email is required'),
  phone: Yup.string().matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number must match format XXX-XXX-XXXX'),
});

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: Store) => state.auth.user);

  const {
    isOpen: isChangePasswordModalOpen,
    onOpen: openChangePasswordModal,
    onClose: closeChangePasswordModal,
  } = useDisclosure();

  const [submitError, setSubmitError] = useState<string | undefined>();

  const toast = useToast();

  const initialValues: FormValues = {
    first_name: userDetails?.first_name ?? '',
    last_name: userDetails?.last_name ?? '',
    title: userDetails?.title ?? '',
    email: userDetails?.email ?? '',
    phone: userDetails?.phone ?? '',
  };

  const handleSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    setSubmitError(undefined);
    try {
      const updatedProfile = await api.auth.setProfile({ ...values, update_source: 'settings_page' });
      dispatch(login(updatedProfile));

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Profile changes saved." status="success" />,
      });
    } catch (error) {
      console.error('[ProfileSettings] Failed to set user account information!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to update profile. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
          <Form>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="1rem" paddingBlockEnd="1rem">
              <GridItem>
                <FormControl isInvalid={Boolean(errors.first_name) && touched.first_name}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="First name"
                    value={values.first_name}
                  />
                  <ErrorMessage component={FormErrorMessage} name="first_name" />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={Boolean(errors.last_name) && touched.last_name}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Last name"
                    value={values.last_name}
                  />
                  <ErrorMessage component={FormErrorMessage} name="last_name" />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={Boolean(errors.title) && touched.title}>
                  <FormLabel>Title</FormLabel>
                  <Input
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Title"
                    value={values.title}
                  />
                  <ErrorMessage component={FormErrorMessage} name="title" />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input disabled readOnly value={values.email} />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={Boolean(errors.phone) && touched.phone}>
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Phone number (XXX-XXX-XXXX)"
                    type="tel"
                    value={values.phone}
                  />
                  <ErrorMessage component={FormErrorMessage} name="phone" />
                </FormControl>
              </GridItem>
            </SimpleGrid>
            <Flex alignItems="center" justifyContent="flex-end">
              {!isSubmitting && submitError && (
                <Text color={theme.colors.brand.error[200]} marginInlineEnd="0.5rem" textStyle="detail">
                  {submitError}
                </Text>
              )}
              <Button
                isDisabled={isSubmitting}
                onClick={openChangePasswordModal}
                marginInlineEnd="0.5rem"
                padding="0.5rem 1.25rem"
                size="md"
                type="button"
                variant="mediumEmphasisV2"
              >
                Change password
              </Button>
              <Button
                isLoading={isSubmitting}
                padding="0.5rem 1.25rem"
                size="md"
                type="submit"
                variant="highEmphasisV2"
              >
                Save
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
      <ChangePasswordModal close={closeChangePasswordModal} open={isChangePasswordModalOpen} />
    </>
  );
};

export default ProfileSettings;
