import {
  Button,
  Center,
  Divider,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getProcoreMe, setAuthToken } from '../../../actions/procore';
import { LogoIcon, ProcoreIcon } from '../../../components/Icon';
import { LocalStorageKeys } from '../../../constants/storage';
import theme from '../../../theme';

export interface ProcoreIntegrationModalContainerProps {
  /** Flag indicating whether or not the modal is open. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the modal. */
  onClose: () => void;
}

const ProcoreIntegrationModalContainer = (props: ProcoreIntegrationModalContainerProps) => {
  const { isOpen, onClose } = props;

  const dispatch = useDispatch();

  const [isWaitingAuthentication, setIsWaitingAuthentication] = useState<boolean>(false);

  const onAuthorize = () => {
    setIsWaitingAuthentication(true);

    const queryParams = new URLSearchParams();
    queryParams.append('response_type', 'code');
    queryParams.append('client_id', window.PROCORE_CLIENT_ID);
    queryParams.append('redirect_uri', window.PROCORE_CALLBACK);

    window.open(`${window.PROCORE_API_PREFIX}oauth/authorize?${queryParams.toString()}`, '_blank');
  };

  const onCloseModal = () => {
    setIsWaitingAuthentication(false);
    onClose();
  };

  const watchLocalStorage = useCallback(
    (event: StorageEvent) => {
      if (event.key !== LocalStorageKeys.ProcoreOAuthFlowComplete) {
        return;
      }
      if (!event.newValue) {
        console.warn('[ProcoreIntegrationModalContainer] Procore access token not provided!');
        return;
      }

      console.debug('[ProcoreIntegrationModalContainer] Procore integrated successfully!');
      dispatch(setAuthToken(event.newValue));
      dispatch(getProcoreMe());
      setIsWaitingAuthentication(false);

      localStorage.removeItem(LocalStorageKeys.ProcoreOAuthFlowComplete);

      onClose();
    },
    [dispatch, onClose]
  );

  useEffect(() => {
    if (!isOpen || !isWaitingAuthentication) {
      return undefined;
    }

    window.addEventListener('storage', watchLocalStorage);

    return () => {
      window.removeEventListener('storage', watchLocalStorage);
    };
  }, [isOpen, isWaitingAuthentication, watchLocalStorage]);

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={Boolean(isOpen)} onClose={onCloseModal} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Procore Integration</ModalHeader>
        <ModalBody>
          <Center aria-hidden marginBlockEnd="0.5rem" paddingBlock="1rem">
            <Icon as={ProcoreIcon} height="2.5rem" width="2.5rem" />
            <Divider
              borderBlock="0.0625rem solid var(--gray-800)"
              height="0.375rem"
              marginBlock={0}
              marginInline="1rem"
              width="7.5rem"
            />
            <Icon as={LogoIcon} color={theme.colors.brand.primary[600]} height="3.25rem" width="3.25rem" />
          </Center>
          {!isWaitingAuthentication && (
            <Text paddingBlockEnd="1.5rem">You are about to sign in with your Procore account.</Text>
          )}
          {isWaitingAuthentication && <Text paddingBlockEnd="1.5rem">Waiting for Procore sign-in...</Text>}
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onCloseModal} padding="0.5rem 1.25rem" size="md" variant="mediumEmphasisV2">
            Cancel
          </Button>
          <Button
            isLoading={isWaitingAuthentication}
            onClick={onAuthorize}
            padding="0.5rem 1.25rem"
            size="md"
            variant="highEmphasisV2"
          >
            Authorize
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProcoreIntegrationModalContainer;
