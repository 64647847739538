import { createIcon as createChakraIcon } from '@chakra-ui/react';
import { AriaAttributes, ElementType, SVGAttributes } from 'react';

import theme from '../theme';

// MARK: Chakra icons
// See https://v2.chakra-ui.com/docs/components/icon#using-the-createicon-function.
// The default viewBox for `createChakraIcon` is 0 0 24 24, so this can be omitted safely for most icons.

/** Note that this icon is different than the `PlusIcon`. It's slightly slimmer. */
export const AddIcon = createChakraIcon({
  displayName: 'AddIcon',
  d: 'M13 11V6H11V11H6V13H11V18H13V13H18V11H13Z',
  defaultProps: {
    role: 'img',
  },
});

export const CalendarIcon = createChakraIcon({
  displayName: 'CalendarIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      key="calendar-icon-path"
      clipRule="evenodd"
      d="M9 3V2H7V3H4C2.89543 3 2 3.89543 2 5V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V5C22 3.89543 21.1046 3 20 3H17V2H15V3H9ZM15 7V5H9V7H7V5H4V8H20V5H17V7H15ZM20 10H4V20H20V10ZM7 16H9V18H7V16ZM9 12H7V14H9V12ZM11 16H13V18H11V16ZM13 12H11V14H13V12ZM15 16H17V18H15V16ZM17 12H15V14H17V12Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
});

export const CalendarWarningIcon = createChakraIcon({
  displayName: 'CalendarWarningIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.37476 3.12476V2.08301L7.29142 2.08301V3.12476H4.16634C3.01575 3.12476 2.08301 4.0575 2.08301 5.20809V20.8331C2.08301 21.9837 3.01575 22.9164 4.16634 22.9164H20.833C21.9836 22.9164 22.9163 21.9837 22.9163 20.8331V5.20809C22.9163 4.0575 21.9836 3.12476 20.833 3.12476H17.708V2.08301L15.6247 2.08301V3.12476H9.37476ZM15.6247 7.29134V5.20809H9.37476V7.29134H7.29142V5.20809H4.16634V8.33301H20.833V5.20809H17.708V7.29134H15.6247ZM20.833 10.4163H4.16634V20.8331H20.833V10.4163ZM7.2915 16.6665H9.37484V18.7498H7.2915V16.6665ZM9.37484 12.4998H7.2915V14.5831H9.37484V12.4998ZM11.458 16.6665H13.5413V18.7498H11.458V16.6665ZM13.5413 12.4998H11.458V14.5831H13.5413V12.4998ZM15.6248 16.6665H17.7081V18.7498H15.6248V16.6665ZM17.7081 12.4998H15.6248V14.5831H17.7081V12.4998Z"
      fill="currentColor"
      key="calendar-warning-icon-path-1"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3156 13.3621C22.6196 12.2126 20.8797 12.2126 20.1837 13.3621L14.703 22.4138C14.007 23.5632 14.877 25 16.2689 25H27.2305C28.6224 25 29.4924 23.5632 28.7964 22.4138L23.3156 13.3621Z"
      fill={theme.colors.brand.warning[100]} // not the color from the design, but a close one from our theme
      key="calendar-warning-icon-path-2"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.792 14.583L22.792 20.4163L20.7087 20.4163L20.7087 14.583L22.792 14.583ZM20.7087 21.6143L20.7087 23.958L22.792 23.958L22.792 21.6143L20.7087 21.6143Z"
      fill={theme.colors.brand.error[200]}
      key="calendar-warning-icon-path-3"
    />,
  ],
  viewBox: '0 0 30 26',
});

export const ChevronDownIcon = createChakraIcon({
  displayName: 'ChevronDownIcon',
  d: 'M18.2929 8.24268L13.4133 13.1222C13.0384 13.4965 12.5298 13.7071 12 13.7071C11.47 13.7071 10.9617 13.4968 10.5867 13.1222L5.70709 8.24268L4.29288 9.65689L9.17288 14.5369C9.92289 15.286 10.94 15.7071 12 15.7071C13.06 15.7071 14.0766 15.2864 14.8266 14.5373L19.7071 9.65689L18.2929 8.24268Z',
  defaultProps: {
    role: 'img',
  },
});

export const ChevronLeftIcon = createChakraIcon({
  displayName: 'ChevronLeftIcon',
  d: 'M14.7071 5.70711L9.82757 10.5867C9.45332 10.9616 9.24268 11.4702 9.24268 12C9.24268 12.53 9.45304 13.0383 9.82757 13.4133L14.7071 18.2929L13.2929 19.7071L8.41292 14.8271C7.66384 14.0771 7.24268 13.06 7.24268 12C7.24268 10.94 7.6634 9.92334 8.41248 9.17334L13.2929 4.29289L14.7071 5.70711Z',
  defaultProps: {
    role: 'img',
  },
});

export const ChevronRightIcon = createChakraIcon({
  displayName: 'ChevronRightIcon',
  d: 'M7.24269 18.2929L12.1223 13.4133C12.4965 13.0384 12.7072 12.5298 12.7072 12C12.7072 11.47 12.4968 10.9617 12.1223 10.5867L7.24269 5.70711L8.65691 4.29289L13.5369 9.1729C14.286 9.92291 14.7072 10.94 14.7072 12C14.7072 13.06 14.2864 14.0767 13.5374 14.8267L8.65691 19.7071L7.24269 18.2929Z',
  defaultProps: {
    role: 'img',
  },
});

export const Degree360Icon = createChakraIcon({
  displayName: 'Degree360Icon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      d="M12 14.23V16.17C6.18003 16.17 1.83003 14.29 1.83003 12.6C1.83003 12 2.39004 11.36 3.38004 10.8V8.95C1.44004 9.82 0.150024 11.05 0.150024 12.61C0.150024 16 6.25003 17.83 11.98 17.83V20L15.98 17L12.27 14.22L12 14.23Z"
      fill="currentColor"
      key="degree360-icon-path-1"
    />,
    <path
      d="M20.89 9.07001V9.63C20.8912 10.058 20.8644 10.4855 20.81 10.91C21.68 11.44 22.17 12.03 22.17 12.59C22.17 13.53 20.67 14.82 17.31 15.59L17.69 17.19C21.59 16.29 23.83 14.61 23.83 12.58C23.83 11.12 22.67 9.93001 20.89 9.07001Z"
      fill="currentColor"
      key="degree360-icon-path-2"
    />,
    <path
      d="M6.60004 13.23C6.87977 13.2595 7.16259 13.2298 7.43002 13.1426C7.69746 13.0554 7.9435 12.9127 8.15207 12.724C8.36063 12.5352 8.52703 12.3046 8.64041 12.0472C8.75379 11.7897 8.81158 11.5113 8.81003 11.23C8.81999 11.1303 8.81999 11.0298 8.81003 10.93C8.81723 10.6445 8.7732 10.36 8.68002 10.09C8.59141 9.78277 8.401 9.51478 8.14003 9.33001C8.03804 9.25068 7.92298 9.18975 7.80003 9.15C8.0143 9.08064 8.20745 8.95801 8.36137 8.7936C8.51529 8.62919 8.62493 8.42839 8.68002 8.21001C8.74598 8.00619 8.78301 7.79412 8.79002 7.58001C8.79528 7.51345 8.79528 7.44658 8.79002 7.38001C8.79501 7.30676 8.79501 7.23326 8.79002 7.16001C8.80467 6.87126 8.75655 6.58277 8.64896 6.31441C8.54137 6.04606 8.37687 5.80422 8.16681 5.60557C7.95675 5.40691 7.70611 5.25616 7.43217 5.1637C7.15824 5.07125 6.86751 5.03929 6.58003 5.07001C4.95003 5.07001 4.38004 6.07001 4.38004 7.53001V8.09H5.84003V7.57001C5.84003 6.78001 5.97004 6.33001 6.57004 6.33001C7.17004 6.33001 7.29002 6.67001 7.29002 7.50001C7.29537 7.60327 7.29537 7.70674 7.29002 7.81C7.23002 8.4 6.99003 8.58001 6.52003 8.58001C6.34031 8.59412 6.15975 8.59412 5.98003 8.58001V9.77001H6.52003C7.06003 9.77001 7.30003 10.05 7.30003 10.87C7.30003 11.69 7.11003 12.06 6.58003 12.06C6.05003 12.06 5.82004 11.61 5.82004 10.82V10.27H4.37003V10.86C4.38003 12.21 5.00004 13.23 6.60004 13.23Z"
      fill="currentColor"
      key="degree360-icon-path-3"
    />,
    <path
      d="M9.70003 9.92C9.69563 10.3596 9.73585 10.7985 9.82004 11.23C9.87342 11.7957 10.1417 12.3195 10.5696 12.6933C10.9976 13.0672 11.5526 13.2627 12.1204 13.2396C12.6881 13.2166 13.2254 12.9767 13.6216 12.5693C14.0178 12.162 14.2427 11.6182 14.25 11.05C14.25 10.91 14.25 10.76 14.25 10.6C14.2562 10.2391 14.2124 9.87898 14.12 9.53C14.0833 9.3746 14.0297 9.22369 13.96 9.08001C13.8365 8.77373 13.6212 8.5132 13.3436 8.33416C13.0661 8.15511 12.74 8.06634 12.41 8.08001C12.1698 8.07019 11.9312 8.12426 11.7186 8.23668C11.5061 8.34911 11.3271 8.51588 11.2 8.72001V7.92C11.1893 7.73349 11.1893 7.54652 11.2 7.36001C11.27 6.70001 11.5 6.36001 11.98 6.36001C12.46 6.36001 12.67 6.76001 12.69 7.36001V7.75H14.13V7.44001C14.13 5.97001 13.42 5.03 12.03 5.03C11.467 5.00734 10.917 5.20254 10.4942 5.57503C10.0714 5.94752 9.80846 6.46863 9.76003 7.03V7.08001C9.73773 7.18572 9.72105 7.29252 9.71004 7.4C9.6688 7.6915 9.64873 7.98561 9.65003 8.28C9.64483 8.36659 9.64483 8.45341 9.65003 8.54V9.54C9.64505 9.61992 9.64505 9.70008 9.65003 9.78L9.70003 9.92ZM11.99 9.24001C12.54 9.24001 12.76 9.73001 12.76 10.61C12.76 11.49 12.54 12 12 12C11.46 12 11.23 11.51 11.23 10.65C11.23 9.79 11.45 9.24001 12 9.24001H11.99Z"
      fill="currentColor"
      key="degree360-icon-path-4"
    />,
    <path
      d="M17.52 13.23C19.02 13.23 19.71 12.23 19.86 10.42C19.86 10.17 19.86 9.91001 19.86 9.63V8.63C19.86 6.35 19.2 5.03 17.49 5.03C16.07 5.03 15.37 5.92999 15.17 7.53999V7.73C15.1542 8.02979 15.1542 8.33021 15.17 8.63V9.63C15.1625 10.109 15.1994 10.5877 15.28 11.06C15.51 12.45 16.2 13.23 17.52 13.23ZM16.76 7.8C16.76 6.91 16.92 6.33 17.52 6.33C18.12 6.33 18.28 6.94 18.28 7.86V10.42C18.28 11.34 18.13 11.95 17.52 11.95C16.91 11.95 16.76 11.34 16.76 10.42V7.8Z"
      fill="currentColor"
      key="degree360-icon-path-5"
    />,
  ],
});

export const DeleteIcon = createChakraIcon({
  displayName: 'DeleteIcon',
  d: 'M12.6668 2.66667H10.3335L9.66683 2H6.3335L5.66683 2.66667H3.3335V4H12.6668M4.00016 12.6667C4.00016 13.0203 4.14064 13.3594 4.39069 13.6095C4.64074 13.8595 4.97987 14 5.3335 14H10.6668C11.0205 14 11.3596 13.8595 11.6096 13.6095C11.8597 13.3594 12.0002 13.0203 12.0002 12.6667V4.66667H4.00016V12.6667Z',
  defaultProps: {
    role: 'img',
  },
  viewBox: '0 0 16 16',
});

export const DislikeIcon = createChakraIcon({
  displayName: 'DislikeIcon',
  defaultProps: {
    'aria-label': 'Dislike',
    role: 'img',
  },
  path: (
    <path
      d="M15.2989 3H20.2989V15H16.3079L12.8793 21.0001H11.4077C10.2141 21.0001 9.29692 19.943 9.46586 18.7611L9.46786 18.7471L10.1123 15.0001H5.8142C3.89424 15.0001 2.53858 13.1192 3.14571 11.2978M3.14571 11.2978L4.8417 6.2097C4.84171 6.20969 4.8417 6.20971 4.8417 6.2097C5.28152 4.89015 6.51637 4.0001 7.90729 4.0001H15.2989V3M15.2989 6.0001H7.90729C7.37722 6.0001 6.90668 6.33927 6.73909 6.8421L5.04308 11.9302C4.86763 12.4566 5.25936 13.0001 5.8142 13.0001H12.4857L11.4537 19.0001H11.7187L15.2989 12.7347V6.0001ZM17.299 13H18.2989V5H17.299V13Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export const EditIcon = createChakraIcon({
  displayName: 'EditIcon',
  d: 'M20.8463 3.15375C20.0462 2.35371 19.2914 2 18.5931 2C18.2503 2 17.971 2.08715 17.7646 2.19035C17.6633 2.24103 17.5809 2.295 17.5174 2.3424C17.4857 2.36611 17.4583 2.38835 17.4357 2.40841C17.4253 2.41747 17.4158 2.42623 17.407 2.43439L17.4047 2.43648L17.3916 2.44888L17.3858 2.45472L17.3829 2.45763L17.3814 2.45909L17.3799 2.46055L8 11.8405V16.0004H12.1599L20.1531 8.00648L21.5409 6.61863L21.5424 6.61717L21.5453 6.61426L21.5511 6.60842L21.5635 6.59529C21.5723 6.58618 21.5818 6.5756 21.5916 6.5643C21.6113 6.54169 21.6339 6.51434 21.6576 6.48262C21.705 6.41953 21.759 6.33712 21.8097 6.23539C21.9132 6.02863 22 5.74968 22 5.40691C22 4.70861 21.6463 3.95378 20.8463 3.15375ZM12.9021 9.71132L18.6446 3.96875C18.6797 3.97713 18.7322 3.99464 18.8044 4.03074C18.9491 4.1033 19.1686 4.24953 19.4596 4.54051C19.7506 4.8315 19.8972 5.05139 19.9694 5.19579C20.0055 5.26762 20.023 5.3205 20.0314 5.3555L14.2888 11.0981L12.9021 9.71132ZM12.9017 12.4845L11.5153 11.0982L9.96041 12.653V14.0394H11.3468L12.9017 12.4845ZM4 6C2.89543 6 2 6.89543 2 8V20C2 21.1046 2.89543 22 4 22H15C16.1046 22 17 21.1046 17 20V14L15 16V20H4V8H9L11 6H4Z',
  defaultProps: {
    role: 'img',
  },
});

export const ExternalLinkIcon = createChakraIcon({
  displayName: 'ExternalLinkIcon',
  defaultProps: {
    role: 'img',
  },
  path: (
    <path
      clipRule="evenodd"
      d="M7.00001 5H14.1103C15.4072 5 16.6509 5.51518 17.5679 6.43216C18.4848 7.34915 19 8.59284 19 9.88967V17H17V9.88967C17 9.41713 16.8843 8.95652 16.6685 8.54575L5.70712 19.5071L4.29291 18.0929L15.1804 7.20542C14.8432 7.07099 14.4804 7 14.1103 7H7.00001V5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export const FloorsIcon = createChakraIcon({
  displayName: 'FloorsIcon',
  d: 'M20 2H4C3.46957 2 2.96086 2.21071 2.58578 2.58578C2.21071 2.96086 2 3.46957 2 4V8H4V4H14V16H20V20H10V8H8V20H4V13H2V20C2 20.5304 2.21071 21.0391 2.58578 21.4142C2.96086 21.7893 3.46957 22 4 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V4C22 3.46957 21.7893 2.96086 21.4142 2.58578C21.0391 2.21071 20.5304 2 20 2ZM16 14V4H20V14H16Z',
  defaultProps: {
    role: 'img',
  },
});

export const GoogleIcon = createChakraIcon({
  displayName: 'GoogleIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
      fill="#EA4335"
      key="google-icon-path-1"
    />,
    <path
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
      fill="#4285F4"
      key="google-icon-path-2"
    />,
    <path
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
      fill="#FBBC05"
      key="google-icon-path-3"
    />,
    <path
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
      fill="#34A853"
      key="google-icon-path-4"
    />,
    <path d="M0 0h48v48H0z" fill="none" key="google-icon-path-5" />,
  ],
  viewBox: '0 0 48 48',
});

export const HelpIcon = createChakraIcon({
  displayName: 'HelpIcon',
  d: 'M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12.2581 7.47324C11.7926 7.39339 11.3138 7.48087 10.9066 7.72019C10.4994 7.9595 10.19 8.33521 10.0333 8.78076L9.70147 9.7241L7.8148 9.06041L8.14664 8.11708C8.46012 7.22597 9.07885 6.47455 9.89325 5.99592C10.7077 5.51729 11.6652 5.34233 12.5962 5.50203C13.5273 5.66172 14.3717 6.14578 14.9801 6.86845C15.5883 7.59097 15.9212 8.5054 15.92 9.44983C15.9195 10.9802 14.7849 11.9908 13.9747 12.531C13.5942 12.7846 13.2192 12.9796 12.92 13.1184V14.6712H10.92V12.4489C10.92 12.0185 11.1954 11.6364 11.6038 11.5002L11.6187 11.495C11.634 11.4895 11.6593 11.4804 11.6929 11.4676C11.7603 11.4419 11.8606 11.4018 11.9826 11.3476C12.2294 11.2379 12.5509 11.0764 12.8653 10.8669C13.5549 10.4071 13.92 9.91805 13.92 9.44892L13.92 9.44743C13.9207 8.97511 13.7542 8.51779 13.45 8.15645C13.1459 7.79511 12.7236 7.55309 12.2581 7.47324ZM10.8889 18.6667V16.4444H12.8889V18.6667H10.8889Z',
  defaultProps: {
    role: 'img',
  },
});

export const InfoIcon = createChakraIcon({
  displayName: 'InfoIcon',
  d: 'M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM11 19L11 10H13L13 19H11ZM13 8.22222V6H11V8.22222H13Z',
  defaultProps: {
    role: 'img',
  },
});

export const LikeIcon = createChakraIcon({
  displayName: 'LikeIcon',
  defaultProps: {
    'aria-label': 'Like',
    role: 'img',
  },
  path: (
    <path
      d="M10.4197 3H11.8913C13.0849 3 14.0021 4.05708 13.8331 5.23899L13.8311 5.25303L13.1867 9H17.4848C19.4048 9 20.7604 10.8809 20.1533 12.7023L18.4573 17.7904C18.4573 17.7904 18.4573 17.7904 18.4573 17.7904C18.0175 19.11 16.7826 20 15.3917 20H8V21H3V9H6.99111L10.4197 3ZM8 11.2656L11.5803 5H11.8453L10.8133 11H17.4848C18.0396 11 18.4314 11.5435 18.2559 12.0699L16.5599 17.158C16.3923 17.6608 15.9218 18 15.3917 18H8V11.2656ZM6 19V11H5V19H6Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export const LinkIcon = createChakraIcon({
  displayName: 'LinkIcon',
  defaultProps: {
    role: 'img',
  },
  path: (
    <path
      clipRule="evenodd"
      d="M12 4C10.8511 4 10 4.87771 10 5.86364V10H8V5.86364C8 3.68648 9.83521 2 12 2C14.1648 2 16 3.68648 16 5.86364V10H14V5.86364C14 4.87771 13.1489 4 12 4ZM13 7L13 17H11L11 7H13ZM10 18.1364L10 14H8V18.1364C8 20.3135 9.83521 22 12 22C14.1648 22 16 20.3135 16 18.1364L16 14H14L14 18.1364C14 19.1223 13.1489 20 12 20C10.8511 20 10 19.1223 10 18.1364Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export const LogoIcon = createChakraIcon({
  displayName: 'LogoIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      d="M19.9722 12.6794H15.6902C14.0509 12.6794 12.7111 14.0161 12.7023 15.659V19.9727H14.4408C14.4408 19.9727 14.4408 15.6687 14.4416 15.6572L18.772 19.9999L19.9994 18.7618L15.6752 14.4226C15.6875 14.4226 19.9722 14.4226 19.9722 14.4226V12.6794Z"
      fill="currentColor"
      key="logo-icon-path-1"
    />,
    <path
      d="M11.2735 4H6.99148C5.35225 4 4.01153 5.33669 4.00363 6.97962V11.2933H5.74208C5.74208 11.2933 5.74208 6.9893 5.74296 6.97786L10.0733 11.3206L11.3007 10.0824L6.97567 5.7494C6.98797 5.7494 11.2726 5.74236 11.2726 5.74236L11.2735 4Z"
      fill="currentColor"
      key="logo-icon-path-2"
    />,
    <path
      d="M10.0679 12.6557L5.74459 16.9896C5.74459 16.9773 5.73757 12.6829 5.73757 12.6829H4V16.9746C4 18.6175 5.33369 19.9604 6.97292 19.9692H11.276V18.2268C11.276 18.2268 6.9817 18.2268 6.97029 18.2259L11.3032 13.8859L10.0679 12.6557Z"
      fill="currentColor"
      key="logo-icon-path-3"
    />,
    <path
      d="M12.7002 4.00262V5.74499C12.7002 5.74499 16.9945 5.74499 17.0059 5.74587L12.6729 10.0859L13.9083 11.3162L18.2316 6.98224C18.2316 6.99456 18.2386 11.2889 18.2386 11.2889H19.977V6.99808C19.9762 5.35516 18.6425 4.01142 17.0032 4.0035H12.701L12.7002 4.00262Z"
      fill="currentColor"
      key="logo-icon-path-4"
    />,
  ],
});

export const MarkupsIcon = createChakraIcon({
  displayName: 'MarkupsIcon',
  defaultProps: {
    role: 'img',
  },
  path: (
    <path
      clipRule="evenodd"
      d="M20.75 22.65C20.5861 22.6501 20.4238 22.6174 20.2726 22.5538C20.1215 22.4903 19.9846 22.3972 19.87 22.28L15.59 18H5C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7956 2 15V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H19C19.7956 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5V21.4C22.0005 21.6465 21.9275 21.8876 21.7904 22.0924C21.6532 22.2973 21.4581 22.4566 21.23 22.55C21.0788 22.6166 20.9152 22.6506 20.75 22.65ZM5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V15C4 15.2652 4.10536 15.5196 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16H16.41L20 19.59V5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4H5ZM18 9H6V7H18V9ZM6 13L18 13V11L6 11L6 13Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export const MinusIcon = createChakraIcon({
  displayName: 'MinusIcon',
  defaultProps: {
    role: 'img',
  },
  path: <path d="M18 11H6V13H18V11Z" fill="currentColor" stroke="currentColor" />,
});

export const OnTrackIcon = createChakraIcon({
  displayName: 'OnTrackIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <circle
      cx="10.5"
      cy="10"
      r="8"
      stroke={theme.colors.brand.secondary[400]}
      strokeWidth="4"
      key="clock-icon-circle"
    />,
    <path
      d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10Z"
      fill={theme.colors.brand.secondary[400]}
      key="clock-icon-path-1"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 17.3333C14.5501 17.3333 17.8333 14.0501 17.8333 10C17.8333 5.94991 14.5501 2.66667 10.5 2.66667C6.44991 2.66667 3.16667 5.94991 3.16667 10C3.16667 14.0501 6.44991 17.3333 10.5 17.3333ZM10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20Z"
      fill={theme.colors.brand.secondary[700]}
      key="clock-icon-path-2"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 5.3335H11.1667V9.66683L14.6333 12.2668L13.0333 14.4002L8.5 11.0002V5.3335Z"
      fill={theme.colors.brand.secondary[700]}
      key="clock-icon-path-3"
    />,
  ],
  viewBox: '0 0 21 20',
});

export const PlusIcon = createChakraIcon({
  displayName: 'PlusIcon',
  defaultProps: {
    role: 'img',
  },
  path: <path d="M13 11V6H11V11H6V13H11V18H13V13H18V11H13Z" fill="currentColor" stroke="currentColor" />,
});

export const ProcoreIcon = createChakraIcon({
  displayName: 'PlusIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      d="M46 9.8937C46 6.03976 45.3667 3.50881 43.7547 1.89821C42.1427 0.63273 39.6095 0 35.4643 0H10.5357C6.39048 0 3.79975 0.63273 2.2453 1.89821C0.633288 3.16368 0 5.75215 0 9.8937V31.9245C0 35.7784 0.633288 38.3094 2.2453 39.92C3.85732 41.1855 6.39048 41.8182 10.5357 41.8182H35.4643C39.6095 41.8182 42.2003 41.1855 43.7547 39.92C45.3667 38.6545 46 36.066 46 31.9245V26.5174H35.4643L31.2616 33.8227H15.8898L8.23279 20.7653L15.8898 7.65036H31.2616L35.4068 15.0131H45.9424V9.8937H46Z"
      fill="#000000"
      key="procore-icon-path-1"
    />,
    <path
      d="M14.6364 21.9832L18.7329 29.2727H27.2672L31.3637 21.9832L27.2672 14.6364H18.7329L14.6364 21.9832Z"
      fill="#F47E42"
      key="procore-icon-path-2"
    />,
  ],
  viewBox: '0 0 46 42',
});

export const ProjectDefaultIcon = createChakraIcon({
  displayName: 'ProjectDefaultIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path key="project-default-icon-path-1" d="M0 .001h56v56H0z" fill={theme.colors.brand.gray[100]} />,
    <path
      key="project-default-icon-path-2"
      clipRule="evenodd"
      d="M15.503 8.001h10.704v4.352l-10.748.022L26.273 23.21l-3.077 3.099L12.36 15.452V26.22H8.01V15.452C8.03 11.342 11.393 8 15.503 8Zm21.759 21.67h10.725v4.352H37.24l10.813 10.88L44.976 48 34.14 37.144v10.77h-4.352V37.121c.022-4.11 3.363-7.45 7.473-7.45Zm-14.066-.044L12.382 40.441l-.021-10.748H8.008V40.42c0 4.11 3.34 7.45 7.45 7.472h10.77V43.54h-10.77l10.836-10.836-3.1-3.077Zm6.593-17.274V8l10.55.022H40.558c4.11.022 7.45 3.363 7.45 7.473V26.22h-4.35l-.023-10.748-10.813 10.814-3.099-3.077 10.835-10.857H29.79Z"
      fill={theme.colors.brand.primary[400]}
      fillRule="evenodd"
    />,
  ],
  viewBox: '0 0 56 56',
});

export const SearchIcon = createChakraIcon({
  displayName: 'SearchIcon',
  defaultProps: {
    role: 'img',
  },
  d: 'M16.29 14.88C16.1973 14.7866 16.124 14.6757 16.0742 14.5539C16.0245 14.4321 15.9992 14.3016 16 14.17V7C16 6.20435 15.6839 5.44129 15.1213 4.87868C14.5587 4.31607 13.7956 4 13 4H7C6.20435 4 5.4413 4.31607 4.87869 4.87868C4.31608 5.44129 4 6.20435 4 7V13C4 13.7956 4.31608 14.5587 4.87869 15.1213C5.4413 15.6839 6.20435 16 7 16H13V14H7C6.73478 14 6.48044 13.8946 6.29291 13.7071C6.10537 13.5196 6 13.2652 6 13V7C6 6.73478 6.10537 6.48043 6.29291 6.29289C6.48044 6.10536 6.73478 6 7 6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7V14.17C14.0007 14.9654 14.3172 15.7279 14.88 16.29L18.29 19.71L19.71 18.29L16.29 14.88Z',
});

export const ShareIcon = createChakraIcon({
  displayName: 'ShareIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      key="share-icon-path-1"
      clipRule="evenodd"
      d="M19.8333 7.16667V3H18.1667V7.16667H14V8.83333H18.1667V13H19.8333V8.83333H24V7.16667H19.8333ZM11 7.5C11 9.433 9.43299 11 7.49999 11C5.56699 11 3.99999 9.433 3.99999 7.5C3.99999 5.567 5.56699 4 7.49999 4C9.43299 4 11 5.567 11 7.5ZM4.70448 12H10.0806C11.286 12 12.4271 12.5436 13.1866 13.4796L13.659 14.0618C14.5265 15.1308 15 16.4657 15 17.8424V20H12.5701H2.64019H1.10059e-05L0 17.6295C-5.78419e-06 16.3837 0.387803 15.1687 1.1096 14.1533L1.44421 13.6825C2.19452 12.627 3.40943 12 4.70448 12Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
});

export const SortAscendingIcon = createChakraIcon({
  displayName: 'SortAscendingIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      d="M8.40962 13.4148C8.21057 13.6992 7.78943 13.6992 7.59038 13.4148L5.05071 9.78673C4.81874 9.45534 5.05582 9 5.46033 9H10.5397C10.9442 9 11.1813 9.45534 10.9493 9.78673L8.40962 13.4148Z"
      fill={theme.colors.brand.gray[200]}
      key="sort-ascending-icon-path-1"
    />,
    <path
      d="M8.40962 2.58517C8.21057 2.30081 7.78943 2.30081 7.59038 2.58517L5.05071 6.21327C4.81874 6.54466 5.05582 7 5.46033 7H10.5397C10.9442 7 11.1813 6.54466 10.9493 6.21327L8.40962 2.58517Z"
      fill={theme.colors.brand.gray[800]}
      key="sort-ascending-icon-path-2"
    />,
  ],
  viewBox: '0 0 16 16',
});

export const SortDescendingIcon = createChakraIcon({
  displayName: 'SortDescendingIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      d="M8.40962 13.4148C8.21057 13.6992 7.78943 13.6992 7.59038 13.4148L5.05071 9.78673C4.81874 9.45534 5.05582 9 5.46033 9H10.5397C10.9442 9 11.1813 9.45534 10.9493 9.78673L8.40962 13.4148Z"
      fill={theme.colors.brand.gray[800]}
      key="sort-descending-icon-path-1"
    />,
    <path
      d="M8.40962 2.58517C8.21057 2.30081 7.78943 2.30081 7.59038 2.58517L5.05071 6.21327C4.81874 6.54466 5.05582 7 5.46033 7H10.5397C10.9442 7 11.1813 6.54466 10.9493 6.21327L8.40962 2.58517Z"
      fill={theme.colors.brand.gray[200]}
      key="sort-descending-icon-path-2"
    />,
  ],
  viewBox: '0 0 16 16',
});

export const SortIndeterminateIcon = createChakraIcon({
  displayName: 'SortIndeterminateIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      d="M8.40962 13.4148C8.21057 13.6992 7.78943 13.6992 7.59038 13.4148L5.05071 9.78673C4.81874 9.45534 5.05582 9 5.46033 9H10.5397C10.9442 9 11.1813 9.45534 10.9493 9.78673L8.40962 13.4148Z"
      fill={theme.colors.brand.gray[200]}
      key="sort-indeterminate-icon-path-1"
    />,
    <path
      d="M8.40962 2.58517C8.21057 2.30081 7.78943 2.30081 7.59038 2.58517L5.05071 6.21327C4.81874 6.54466 5.05582 7 5.46033 7H10.5397C10.9442 7 11.1813 6.54466 10.9493 6.21327L8.40962 2.58517Z"
      fill={theme.colors.brand.gray[200]}
      key="sort-indeterminate-icon-path-2"
    />,
  ],
  viewBox: '0 0 16 16',
});

export const TimeIcon = createChakraIcon({
  displayName: 'TimeIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      key="time-icon-path-1"
      fill="currentColor"
      d="M3 16L6 12H4C3.99634 10.0749 4.68701 8.21299 5.94528 6.75598C7.20355 5.29897 8.94502 4.34456 10.8502 4.06787C12.7553 3.79117 14.6963 4.21076 16.3171 5.24963C17.9379 6.2885 19.1296 7.87699 19.6738 9.72361C20.2179 11.5702 20.0779 13.5512 19.2794 15.3029C18.4809 17.0546 17.0776 18.4597 15.3268 19.2603C13.576 20.0608 11.5953 20.2032 9.74802 19.6613C7.90074 19.1194 6.31081 17.9295 5.26999 16.31L4 18C5.4247 19.8996 7.47887 21.2303 9.79492 21.7539C12.111 22.2775 14.5378 21.9598 16.6411 20.8578C18.7443 19.7558 20.3869 17.9413 21.2747 15.739C22.1625 13.5367 22.2377 11.0904 21.4868 8.83772C20.736 6.58507 19.208 4.67311 17.1764 3.44398C15.1447 2.21485 12.742 1.74876 10.3982 2.12912C8.05432 2.50947 5.92232 3.71144 4.38364 5.51996C2.84495 7.32847 2 9.62549 2 12H0"
    />,
    <path key="time-icon-path-2" fill="currentColor" d="M11 6V12.41L15.29 16.71L16.71 15.29L13 11.59V6H11Z" />,
  ],
});

export const TriangleUpIcon = createChakraIcon({
  displayName: 'TriangleUpIcon',
  d: 'M11.1679 5.24807C11.5638 4.65434 12.4362 4.65434 12.8321 5.24808L20.9635 17.4453C21.4066 18.1099 20.9302 19 20.1315 19H3.86852C3.06982 19 2.59343 18.1099 3.03647 17.4453L11.1679 5.24807Z',
  defaultProps: {
    role: 'img',
  },
});

export const UpdateIcon = createChakraIcon({
  displayName: 'UpdateIcon',
  d: 'M3 16L6 12H4C3.99634 10.0749 4.68701 8.21299 5.94528 6.75598C7.20355 5.29897 8.94502 4.34456 10.8502 4.06787C12.7553 3.79117 14.6963 4.21076 16.3171 5.24963C17.9379 6.2885 19.1296 7.87699 19.6738 9.72361C20.2179 11.5702 20.0779 13.5512 19.2794 15.3029C18.4809 17.0546 17.0776 18.4597 15.3268 19.2603C13.576 20.0608 11.5953 20.2032 9.74802 19.6613C7.90074 19.1194 6.31081 17.9295 5.26999 16.31L4 18C5.4247 19.8996 7.47887 21.2303 9.79492 21.7539C12.111 22.2775 14.5378 21.9598 16.6411 20.8578C18.7443 19.7558 20.3869 17.9413 21.2747 15.739C22.1625 13.5367 22.2377 11.0904 21.4868 8.83772C20.736 6.58507 19.208 4.67311 17.1764 3.44398C15.1447 2.21485 12.742 1.74876 10.3982 2.12912C8.05432 2.50947 5.92232 3.71144 4.38364 5.51996C2.84495 7.32847 2 9.62549 2 12H0',
  defaultProps: {
    role: 'img',
  },
});

/**
 * A status icon intended to warn the user about a result or input. Since this is a two-tone icon with a specific
 * meaning, the colors are fixed.
 */
export const WarningIcon = createChakraIcon({
  displayName: 'WarningIcon',
  defaultProps: {
    role: 'img',
  },
  path: [
    <path
      d="M11.168 5.24807C11.5638 4.65434 12.4362 4.65434 12.8321 5.24808L20.9635 17.4453C21.4066 18.1099 20.9302 19 20.1315 19H3.86852C3.06982 19 2.59343 18.1099 3.03647 17.4453L11.168 5.24807Z"
      fill={theme.colors.brand.warning[200]}
      key="warning-icon-path-1"
    />,
    <path
      clipRule="evenodd"
      d="M13 8L13 13.94L11 13.94L11 8L13 8Z"
      fill={theme.colors.brand.gray[700]}
      fillRule="evenodd"
      key="warning-icon-path-2"
    />,
    <path
      d="M11 14.975L11 17L13 17L13 14.975L11 14.975Z"
      fill={theme.colors.brand.gray[700]}
      key="warning-icon-path-3"
    />,
  ],
});

// MARK: Custom icons
// We should port these to Chakra

/**
 * Create a new icon given an array of SVG element attributes. By default, each entry is rendered as an SVG <path>, but
 * an optional element type (i.e. HTML tag) may be passed to override this behavior. By default, all icons are
 * intrinsically sized at 24 by 24 pixels.
 */
function createIcon(
  attributeList: (SVGAttributes<SVGElement> & { elementType?: ElementType })[],
  { width, height } = { width: 24, height: 24 }
) {
  return (iconProps: AriaAttributes & { className?: string }) => (
    <svg
      fill="none"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="https://www.w3.org/2000/svg"
      role="img"
      {...iconProps}
    >
      {attributeList.map(({ elementType: El = 'path', ...pathAttributes }, index) => (
        <El key={`icon-element-${index}`} {...pathAttributes} />
      ))}
    </svg>
  );
}

export const ArrowBottomLeft = createIcon(
  [
    {
      d: 'M23.2578 8.25195L8.25992 23.2498',
      stroke: 'currentColor',
      strokeWidth: 2,
      strokeMiterlimit: 10,
    },
    {
      d: 'M6.67383 8.33105L6.62135 19.2815C6.60644 22.3933 9.11699 24.9039 12.2287 24.8889L23.1792 24.8364',
      stroke: 'currentColor',
      strokeWidth: 2,
      strokeMiterlimit: 10,
    },
  ],
  { height: 32, width: 32 }
);

export const BrighteningIconFull = createIcon([
  {
    d: 'M17.9634 7.45099C17.5571 6.91908 17.0811 6.44314 16.5492 6.03675L19.7831 2.80291L21.1973 4.21711L17.9634 7.45099Z',
    fill: 'currentColor',
  },
  {
    d: 'M19.4339 13C19.4775 12.6729 19.5 12.3391 19.5 12C19.5 11.6609 19.4775 11.3271 19.4339 11H24V13H19.4339Z',
    fill: 'currentColor',
  },
  {
    d: 'M16.5493 17.9632C17.0812 17.5568 17.5571 17.0809 17.9634 16.549L21.1973 19.7828L19.7831 21.1971L16.5493 17.9632Z',
    fill: 'currentColor',
  },
  {
    d: 'M11 19.4339C11.3271 19.4775 11.6609 19.5 12 19.5C12.3391 19.5 12.6729 19.4775 13 19.4339V24H11V19.4339Z',
    fill: 'currentColor',
  },
  {
    d: 'M6.03653 16.549C6.4429 17.0809 6.91881 17.5568 7.45071 17.9632L4.21687 21.1971L2.80266 19.7828L6.03653 16.549Z',
    fill: 'currentColor',
  },
  {
    d: 'M4.56609 11C4.5225 11.3271 4.5 11.6609 4.5 12C4.5 12.3391 4.5225 12.6729 4.56609 13H0V11H4.56609Z',
    fill: 'currentColor',
  },
  {
    d: 'M6.03655 7.45103L2.80266 4.21711L4.21687 2.80291L7.45073 6.03678C6.91883 6.44317 6.44292 6.91911 6.03655 7.45103Z',
    fill: 'currentColor',
  },
  {
    d: 'M13 4.56609C12.6729 4.5225 12.3391 4.5 12 4.5C11.6609 4.5 11.3271 4.5225 11 4.56609V0H13V4.56609Z',
    fill: 'currentColor',
  },
  {
    clipRule: 'evenodd',
    d: 'M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12Z',
    fill: 'currentColor',
    fillRule: 'evenodd',
  },
]);

export const BrighteningIconPartial = createIcon([
  {
    d: 'M17.9634 7.45099C17.5571 6.91908 17.0811 6.44314 16.5492 6.03675L19.7831 2.80291L21.1973 4.21711L17.9634 7.45099Z',
    fill: 'currentColor',
  },
  {
    d: 'M19.4339 13C19.4775 12.6729 19.5 12.3391 19.5 12C19.5 11.6609 19.4775 11.3271 19.4339 11H24V13H19.4339Z',
    fill: 'currentColor',
  },
  {
    d: 'M16.5493 17.9632C17.0812 17.5568 17.5571 17.0809 17.9634 16.549L21.1973 19.7828L19.7831 21.1971L16.5493 17.9632Z',
    fill: 'currentColor',
  },
  {
    d: 'M11 19.4339C11.3271 19.4775 11.6609 19.5 12 19.5C12.3391 19.5 12.6729 19.4775 13 19.4339V24H11V19.4339Z',
    fill: 'currentColor',
  },
  {
    d: 'M6.03653 16.549C6.4429 17.0809 6.91881 17.5568 7.45071 17.9632L4.21687 21.1971L2.80266 19.7828L6.03653 16.549Z',
    fill: 'currentColor',
  },
  {
    d: 'M4.56609 11C4.5225 11.3271 4.5 11.6609 4.5 12C4.5 12.3391 4.5225 12.6729 4.56609 13H0V11H4.56609Z',
    fill: 'currentColor',
  },
  {
    d: 'M6.03655 7.45103L2.80266 4.21711L4.21687 2.80291L7.45073 6.03678C6.91883 6.44317 6.44292 6.91911 6.03655 7.45103Z',
    fill: 'currentColor',
  },
  {
    d: 'M13 4.56609C12.6729 4.5225 12.3391 4.5 12 4.5C11.6609 4.5 11.3271 4.5225 11 4.56609V0H13V4.56609Z',
    fill: 'currentColor',
  },
  {
    clipRule: 'evenodd',
    d: 'M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12Z',
    fill: 'currentColor',
    fillRule: 'evenodd',
  },
  {
    d: 'M12 7C13.3261 7 14.5978 7.52678 15.5355 8.46446C16.4732 9.40214 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5978 16.4732 13.3261 17 12 17V7Z',
    fill: 'currentColor',
  },
]);

export const ChatIcon = createIcon(
  [
    {
      d: 'M2.25 3.75V11.25C2.25 11.6478 2.40803 12.0294 2.68934 12.3107C2.97064 12.592 3.35218 12.75 3.75 12.75H12L15.4275 16.1775C15.4533 16.2043 15.4864 16.2227 15.5227 16.2306C15.5591 16.2385 15.5969 16.2354 15.6315 16.2218C15.666 16.2081 15.6957 16.1845 15.7168 16.1539C15.7379 16.1233 15.7495 16.0872 15.75 16.05V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40803 14.6478 2.25 14.25 2.25H3.75C3.35218 2.25 2.97064 2.40803 2.68934 2.68934C2.40803 2.97064 2.25 3.35218 2.25 3.75Z',
      stroke: 'currentColor',
      strokeWidth: 2,
      strokeMiterlimit: 10,
    },
  ],
  { width: 18, height: 18 }
);

/**
 * A status icon conveying a successful result or input. Since this is a two-tone icon with a specific meaning, the
 * colors are fixed.
 */
export const CheckIcon = createIcon(
  [
    {
      elementType: 'circle',
      cx: 6,
      cy: 6,
      r: 6,
      fill: theme.colors.brand.secondary['200'],
    },
    {
      clipRule: 'evenodd',
      d: 'M9.55081 3.82012L5.38415 8.82012C5.29421 8.92804 5.16303 8.99314 5.02269 8.99951C4.88236 9.00588 4.74582 8.95291 4.64648 8.85358L2.14648 6.35358L2.85359 5.64647L4.96644 7.75932L8.78259 3.17993L9.55081 3.82012Z',
      fillRule: 'evenodd',
      fill: theme.colors.brand.secondary['700'],
    },
  ],
  { width: 12, height: 12 }
);

export const CloseIcon = createIcon([
  { d: 'M7 7L17 17', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: 2 },
  { d: 'M17 7L7 17', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: 2 },
]);

export const CollapseIcon = createIcon([
  {
    d: 'M7 15H3V17H7V21H9V17C9 16.4696 8.78929 15.9609 8.41422 15.5858C8.03914 15.2107 7.53043 15 7 15Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  },
  {
    d: 'M15 17V21H17V17H21V15H17C16.4696 15 15.9609 15.2107 15.5858 15.5858C15.2107 15.9609 15 16.4696 15 17Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  },
  {
    d: 'M7 7H3V9H7C7.53043 9 8.03914 8.78929 8.41422 8.41422C8.78929 8.03914 9 7.53043 9 7V3H7V7Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  },
  {
    d: 'M17 7V3H15V7C15 7.53043 15.2107 8.03914 15.5858 8.41422C15.9609 8.78929 16.4696 9 17 9H21V7H17Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  },
]);

/**
 * A status icon conveying that something is in a neutral or indeterminate state. Will inherit the current color.
 */
export const DotIcon = createIcon([{ cx: 6, cy: 6, elementType: 'circle', fill: 'currentColor', r: 4 }], {
  height: 12,
  width: 12,
});

export const DoubleRightArrowIcon = createIcon([
  {
    d: 'M11.77 6.64L10.23 5.36L5.76999 10.72C5.47066 11.0794 5.30676 11.5323 5.30676 12C5.30676 12.4677 5.47066 12.9206 5.76999 13.28L10.23 18.64L11.77 17.36L7.29999 12L11.77 6.64Z',
    fill: 'currentColor',
    transform: 'scale(-1,1) translate(-24,0)',
  },
  {
    d: 'M17.47 6.64L15.93 5.36L11.46 10.72C11.1607 11.0794 10.9967 11.5323 10.9967 12C10.9967 12.4677 11.1607 12.9206 11.46 13.28L15.93 18.64L17.47 17.36L13 12L17.47 6.64Z',
    fill: 'currentColor',
    transform: 'scale(-1,1) translate(-24,0)',
  },
]);

export const DownloadIcon = createIcon([
  {
    d: 'M20 13V19H4V13H2V19C2 19.5304 2.21071 20.0391 2.58578 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V13H20Z',
    fill: 'currentColor',
  },
  {
    d: 'M12 18C12.4573 18.0043 12.9109 17.9182 13.3348 17.7466C13.7587 17.5749 14.1445 17.3212 14.47 17L17.71 13.77L16.29 12.35L13.06 15.59H13V3H11V15.57H10.94L7.71001 12.33L6.28999 13.75L9.53 16.98C9.85386 17.3048 10.2389 17.5622 10.6629 17.7373C11.0868 17.9124 11.5413 18.0017 12 18Z',
    fill: 'currentColor',
  },
]);

/**
 * A status icon conveying that an error has occurred or an input is in an invalid state. Since this is a two-tone icon
 * with a specific meaning, the colors are fixed.
 */
export const ErrorIcon = createIcon(
  [
    {
      elementType: 'circle',
      cx: 6,
      cy: 6,
      fill: theme.colors.brand.error['100'],
      r: 6,
    },
    {
      clipRule: 'evenodd',
      d: 'M5.00881 6L2 2.99119L2.99119 2L6 5.00881L9.00881 2L10 2.99119L6.99119 6L10 9.00881L9.00881 10L6 6.99119L2.99119 10L2 9.00881L5.00881 6Z',
      fill: theme.colors.brand.error['200'],
      fillRule: 'evenodd',
    },
  ],
  { height: 12, width: 12 }
);

export const ExpandIcon = createIcon([
  {
    d: 'M3 5V9H5V5H9V3H5C4.46957 3 3.96086 3.21071 3.58578 3.58578C3.21071 3.96086 3 4.46957 3 5Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  },
  {
    d: 'M5 15H3V19C3 19.5304 3.21071 20.0391 3.58578 20.4142C3.96086 20.7893 4.46957 21 5 21H9V19H5V15Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  },
  {
    d: 'M19 3H15V5H19V9H21V5C21 4.46957 20.7893 3.96086 20.4142 3.58578C20.0391 3.21071 19.5304 3 19 3Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  },
  {
    d: 'M19 19H15V21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V15H19V19Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeMiterlimit: 10,
  },
]);

export const FilterIcon = createIcon([
  {
    d: 'M14.8467 8.94828C15.6725 8.94828 16.3712 8.39831 16.6412 7.64313H19.3886C19.7141 7.64313 20 7.34762 20 6.97824C20 6.60886 19.7141 6.32157 19.3886 6.32157H16.6412C16.3792 5.55818 15.6725 5 14.8467 5C14.0208 5 13.3062 5.55818 13.0442 6.32157H4.63524C4.27792 6.32157 4 6.60886 4 6.97824C4 7.34762 4.27792 7.64313 4.63524 7.64313H13.0521C13.3141 8.39831 14.0208 8.94828 14.8467 8.94828ZM14.8467 7.93864C14.3226 7.93864 13.9176 7.51179 13.9176 6.97003C13.9176 6.42828 14.3226 6.00964 14.8467 6.00964C15.3707 6.00964 15.7757 6.42828 15.7757 6.97003C15.7757 7.51179 15.3707 7.93864 14.8467 7.93864ZM4.60347 11.3287C4.27792 11.3287 4 11.6242 4 11.9936C4 12.363 4.27792 12.6503 4.60347 12.6503H7.44615C7.70819 13.4219 8.41489 13.9719 9.24069 13.9719C10.0665 13.9719 10.7732 13.4219 11.0432 12.6503H19.3568C19.7141 12.6503 20 12.363 20 11.9936C20 11.6242 19.7141 11.3287 19.3568 11.3287H11.0352C10.7732 10.5736 10.0665 10.0236 9.24069 10.0236C8.41489 10.0236 7.70819 10.5736 7.44615 11.3287H4.60347ZM9.24069 12.954C8.72457 12.954 8.31166 12.5272 8.31166 11.9936C8.31166 11.4519 8.72457 11.0332 9.24069 11.0332C9.76476 11.0332 10.1697 11.4519 10.1697 11.9936C10.1697 12.5272 9.76476 12.954 9.24069 12.954ZM14.8467 18.979C15.6725 18.979 16.3792 18.4291 16.6412 17.6657H19.3886C19.7141 17.6657 20 17.3784 20 17.009C20 16.6396 19.7141 16.3441 19.3886 16.3441H16.6412C16.3792 15.5807 15.6725 15.039 14.8467 15.039C14.0208 15.039 13.3141 15.5807 13.0521 16.3441H4.63524C4.27792 16.3441 4 16.6396 4 17.009C4 17.3784 4.27792 17.6657 4.63524 17.6657H13.0442C13.3141 18.4291 14.0208 18.979 14.8467 18.979ZM14.8467 17.9694C14.3226 17.9694 13.9176 17.5426 13.9176 17.009C13.9176 16.459 14.3226 16.0486 14.8467 16.0486C15.3707 16.0486 15.7757 16.459 15.7757 17.009C15.7757 17.5426 15.3707 17.9694 14.8467 17.9694Z',
    fill: 'currentColor',
  },
]);

export const ProgressTrackingIcon = createIcon([
  {
    d: 'M5.68999 7.05L4.25999 5.63C3.00837 7.162 2.23927 9.03081 2.04999 11H4.04999C4.22507 9.55811 4.79232 8.19188 5.68999 7.05Z',
    fill: 'currentColor',
  },
  {
    d: 'M11 4V2C9.04826 2.19887 7.19815 2.96744 5.67999 4.21L7.09999 5.64C8.22774 4.75067 9.57559 4.18388 11 4Z',
    fill: 'currentColor',
  },
  {
    d: 'M13 2V4C14.9218 4.25592 16.6854 5.20107 17.9626 6.6597C19.2399 8.11833 19.944 9.99118 19.944 11.93C19.944 13.8688 19.2399 15.7417 17.9626 17.2003C16.6854 18.6589 14.9218 19.6041 13 19.86V21.86C15.4305 21.5709 17.6705 20.4004 19.2959 18.5704C20.9212 16.7403 21.8188 14.3776 21.8188 11.93C21.8188 9.48237 20.9212 7.11974 19.2959 5.28965C17.6705 3.45955 15.4305 2.2891 13 2Z',
    fill: 'currentColor',
  },
  {
    d: 'M5.67999 19.76C7.19458 21.0134 9.04502 21.7925 11 22V20C9.57758 19.822 8.22991 19.2622 7.09999 18.38L5.67999 19.76Z',
    fill: 'currentColor',
  },
  {
    d: 'M4.06999 13H2.06999C2.26886 14.9517 3.03742 16.8018 4.27999 18.32L5.70999 16.9C4.82066 15.7723 4.25387 14.4244 4.06999 13Z',
    fill: 'currentColor',
  },
  {
    d: 'M16.4626 7.78L10.9026 14.48L7.75264 11.36L6.37264 12.74L10.2526 16.62C10.438 16.7987 10.6852 16.899 10.9426 16.9H10.9926C11.1269 16.8917 11.258 16.8565 11.3783 16.7963C11.4986 16.7362 11.6055 16.6524 11.6926 16.55L17.9626 9.03L16.4626 7.78Z',
    fill: 'currentColor',
  },
]);

export const SnapshotIcon = createIcon([
  {
    d: 'M1 4.05V8.23H3L3.04999 4H9V2H3.04999C2.50629 2 1.98488 2.21598 1.60043 2.60043C1.21598 2.98488 1 3.50631 1 4.05Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 0.5,
    strokeMiterlimit: 10,
  },
  {
    d: 'M3 13.45H1V17.64C1.01305 18.175 1.23478 18.6837 1.61783 19.0574C2.00087 19.4311 2.51485 19.6402 3.04999 19.64H9V17.64H3V13.45Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 0.5,
    strokeMiterlimit: 10,
  },
  {
    d: 'M19 8.23H21V4.05C21.0067 3.78318 20.9599 3.51772 20.8624 3.26927C20.7648 3.02082 20.6186 2.79439 20.4322 2.60335C20.2458 2.4123 20.0231 2.2605 19.7771 2.15688C19.5311 2.05325 19.2669 1.99992 19 2H12.81V4L19.04 4.05L19 8.23Z',
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 0.5,
    strokeMiterlimit: 10,
  },
  {
    d: 'M22.36 15H20.44L19.95 13.52C19.9079 13.3943 19.8273 13.2851 19.7196 13.2079C19.6119 13.1307 19.4825 13.0895 19.35 13.09H15.15C15.0175 13.0895 14.8881 13.1307 14.7804 13.2079C14.6727 13.2851 14.5921 13.3943 14.55 13.52L14.06 15H12.14C11.9703 15 11.8075 15.0674 11.6874 15.1875C11.5674 15.3075 11.5 15.4703 11.5 15.64V21.39C11.5 21.5597 11.5674 21.7225 11.6874 21.8426C11.8075 21.9626 11.9703 22.03 12.14 22.03H22.36C22.5297 22.03 22.6925 21.9626 22.8126 21.8426C22.9326 21.7225 23 21.5597 23 21.39V15.64C23 15.4703 22.9326 15.3075 22.8126 15.1875C22.6925 15.0674 22.5297 15 22.36 15ZM17.25 20.34C16.7951 20.34 16.3504 20.2051 15.9722 19.9524C15.594 19.6997 15.2992 19.3404 15.1251 18.9202C14.951 18.4999 14.9055 18.0375 14.9942 17.5913C15.0829 17.1451 15.302 16.7353 15.6237 16.4137C15.9453 16.092 16.3551 15.8729 16.8013 15.7842C17.2475 15.6955 17.7099 15.741 18.1302 15.9151C18.5504 16.0892 18.9097 16.384 19.1624 16.7622C19.4151 17.1404 19.55 17.5851 19.55 18.04C19.5421 18.6448 19.2963 19.2221 18.8658 19.647C18.4354 20.0719 17.8548 20.3101 17.25 20.31V20.34Z',
    fill: 'currentColor',
  },
]);

export const MoreVerticalIcon = createIcon([
  {
    d: 'M14 4C14 5.10457 13.1046 6 12 6C10.8954 6 10 5.10457 10 4C10 2.89543 10.8954 2 12 2C13.1046 2 14 2.89543 14 4ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20C10 21.1046 10.8954 22 12 22Z',
    fill: 'currentColor',
  },
]);
