import {
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { UserProfile } from '../../@types/api/v0/rest/UserProfile';
import NylasIntegrationApi from '../../api/v1/integration/NylasIntegrationApi';
import { GoogleIcon } from '../../components/Icon';
import Toast from '../../components/Toast';
import { PendoTopic } from '../../constants/analytics';
import { AuthQueryKeys, NylasIntegrationQueryKeys, QueryTopics } from '../../constants/queries';
import { LocalStorageKeys } from '../../constants/storage';
import theme from '../../theme';

export interface NylasAuthenticationModalProps {
  /** Flag indicating whether or not the modal is open. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the modal. */
  onClose: () => void;
}

/**
 * Top-level container component for the modal rendered when the user begins the authentication flow for a Nylas email
 * provider. This component is responsible for:
 *
 * 1. Showing instructions and prompting the user to select a provider.
 * 2. Retrieving the provider's authentication redirect URL.
 * 3. Opening it in a new tab.
 * 4. Listening for changes in `localStorage` indicating that the authentication flow completed successfully in the
 *    other tab.
 * 5. Wrapping up by updating the user profile, removing the temporary local storage entry, and closing the modal.
 *
 * For the rest of the process, see `NylasCallbackRedirectContainer`.
 *
 * @param props The props for this modal.
 */
const NylasAuthenticationModalContainer = (props: NylasAuthenticationModalProps) => {
  const { isOpen, onClose } = props;

  const [provider, setProvider] = useState<string>();

  const queryClient = useQueryClient();

  const toast = useToast();

  const nylasAuthenticationUrlQuery = useQuery({
    enabled: Boolean(isOpen) && Boolean(provider),
    queryKey: [QueryTopics.NYLAS_INTEGRATION, NylasIntegrationQueryKeys.AUTHENTICATION_URL, provider],
    queryFn: async ({ signal }) =>
      (await NylasIntegrationApi.getNylasAuthenticationUrl(provider ?? '', { signal })).data,
  });

  const watchLocalStorage = useCallback(
    (event: StorageEvent) => {
      if (event.key !== LocalStorageKeys.NylasOAuthFlowComplete) {
        return;
      }
      if (event.newValue !== 'true') {
        console.warn(
          '[NylasAuthenticationModalContainer] Local storage entry did not match expected value',
          event.newValue
        );
        return;
      }

      console.debug('[NylasAuthenticationModalContainer] Nylas integrated successfully.');

      // Update user profile: manually set `is_nylas_integrated` to `true`.
      const queryKey = [QueryTopics.AUTH, AuthQueryKeys.USER_PROFILE];
      const nextUserProfile = { ...queryClient.getQueryData<UserProfile>(queryKey), is_nylas_integrated: true };
      queryClient.setQueryData(queryKey, nextUserProfile);

      localStorage.removeItem(LocalStorageKeys.NylasOAuthFlowComplete);

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast {...props} title="Success" description="Email access enabled for OnsiteIQ Context." status="success" />
        ),
      });

      onClose();
    },
    [onClose, queryClient, toast]
  );

  // When the authentication URL becomes available, open it in a new tab.
  useEffect(() => {
    if (!nylasAuthenticationUrlQuery.data?.authentication_url) {
      return;
    }

    window.open(nylasAuthenticationUrlQuery.data.authentication_url, '_blank');
  }, [nylasAuthenticationUrlQuery.data]);

  // When the authentication URL becomes available, listen to local storage changes. When the user authenticates on the
  // selected email provider in the newly opened tab, they will be redirected back to a callback page (see
  // `NylasCallbackRedirectContainer`). That component should set this value in local storage and close itself.
  useEffect(() => {
    if (!nylasAuthenticationUrlQuery.data?.authentication_url) {
      return undefined;
    }

    window.addEventListener('storage', watchLocalStorage);

    return () => {
      window.removeEventListener('storage', watchLocalStorage);
    };
  }, [nylasAuthenticationUrlQuery.data, watchLocalStorage]);

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={Boolean(isOpen)} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Heading as="h1" size="lg">
            Context Email Access
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text paddingBottom="1rem">
            To enable OnsiteIQ Context, you must authenticate with an email provider below. After selecting a provider,
            a new tab will open.
          </Text>
          <Text paddingBottom="1rem">Access may be revoked later from the Settings page.</Text>
          {!nylasAuthenticationUrlQuery.isFetching && nylasAuthenticationUrlQuery.isError && (
            <Text color={theme.colors.brand.error[200]} paddingBottom="1rem">
              An error occurred. Please try again later.
            </Text>
          )}
          <Button
            data-pendo-label="authenticate with google"
            data-pendo-topic={PendoTopic.CONTEXT}
            isDisabled={nylasAuthenticationUrlQuery.isError}
            isLoading={
              nylasAuthenticationUrlQuery.isFetching || Boolean(nylasAuthenticationUrlQuery.data?.authentication_url)
            }
            leftIcon={<Icon aria-hidden as={GoogleIcon} height="1.25rem" width="1.25rem" />}
            onClick={() => setProvider('google')}
            paddingInline="1rem"
            size="md"
            variant="outline"
          >
            Continue with Google
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NylasAuthenticationModalContainer;
