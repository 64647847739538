import { Flex } from '@chakra-ui/react';
import { differenceInCalendarDays, endOfDay, format, isAfter, isBefore } from 'date-fns';
import { max, min } from 'lodash';
import { useMemo } from 'react';
import { CartesianGrid, Dot, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';

import theme from '../../theme';
import ChartTooltip from './ChartTooltip';

export interface ChartPoint {
  date: number;
  value: number;
}

export interface ProgressChartProps {
  /** The projected completion date for the project expressed as a number. */
  baselineCompletionDate?: number;
  /** The start date for the project expressed as a number. */
  baselineStartDate?: number;
  /** A set of points to chart. */
  data: ChartPoint[];
  /** Flag indicating whether or not chart points have momentum or delay indicators. */
  hasMomentum?: boolean | null;
  /**
   * If the `highlightMode` prop has been omitted or passed with a value of `duration`, chart points this number of days
   * from the final point will be highlighted if the `minDurationToHighlight` is met.
   */
  highlightInterval?: number;
  /**
   * If the `highlightMode` prop has been passed with a value of `limit` and the `minDurationToHighlight` has been met,
   * up to this number of chart points whose x coordinate is less than or equal to `highlightLimitMaxDate` will be
   * highlighted.
   */
  highlightLimit?: number;
  /**
   * Chart points from an x coordinate less than or equal to this value will be highlighted. Defaults to `new Date()` if omitted.
   */
  highlightLimitMaxDate?: Date;
  /**
   * If the chart has at least `minDurationToHighlight` days between its first and last data points, chart points will
   * be highlighted using one of these two strategies:
   *
   * - If this prop is omitted or set to `duration`, then `highlightInterval` days of chart data will be highlighted
   *   from the final point to the left of the chart.
   * - If this prop is set to `limit`, then up to `highlightLimit` chart points from an x coordinate less than or equal
   *   to `highlightLimitMaxDate` will be highlighted.
   */
  highlightMode?: 'duration' | 'limit';
  /**
   * In order to highlight chart points, there must be a distance of at least this number of days between the first data
   * point and the last.
   */
  minDurationToHighlight?: number;
  /** Minimum height value for the chart. For responsiveness, this should be in `rem` units. */
  minHeight?: string;
  /** Message to show if no chart points are provided. */
  noDataMessage?: string;
}

interface DotProps {
  index: number;
  dataKey: string;
  cx: number;
  cy: number;
  r: number;
  fill: string;
  strokeWidth: number;
  stroke: string;
  payload: Record<string, any>;
  value: number;
}

interface HighlightDotProps {
  cx: number;
  cy: number;
  variant?: 'momentum' | 'warning';
}

const lineStrokeColor = '#000000';
const lineStrokeWidth = 2;
const gridStrokeColor = theme.colors.brand.gray[100];
const xAxisTextColor = theme.colors.brand.gray[600];
const yAxisTextColor = theme.colors.brand.gray[600];
const defaultHighlightColor = theme.colors.brand.primary[400];
const defaultHighlightColorMediumStop = theme.colors.brand.primary[300];
const defaultHighlightColorLightStop = theme.colors.brand.primary[100];
const momentumHighlightColor = theme.colors.brand.secondary[500];
const momentumHighlightColorMediumStop = theme.colors.brand.secondary[300];
const momentumHighlightColorLightStop = theme.colors.brand.secondary[100];
const warningHighlightColor = theme.colors.brand.warning[200];
const hoverDotColor = theme.colors.brand.gray[600];

/** Render a dot intended to be used as part of a highlight line. */
const HighlightDot = (props: HighlightDotProps) => {
  const { cx, cy, variant = 'default' } = props;

  let stopColors: string[];
  switch (variant) {
    case 'momentum':
      stopColors = [momentumHighlightColor, momentumHighlightColorMediumStop, momentumHighlightColorLightStop];
      break;
    case 'warning':
      stopColors = [warningHighlightColor, warningHighlightColor, warningHighlightColor];
      break;
    default:
      stopColors = [defaultHighlightColor, defaultHighlightColorMediumStop, defaultHighlightColorLightStop];
  }

  return (
    <svg x={cx - 11} y={cy - 11} width="22" height="22" viewBox="0 0 22 22">
      <circle cx="11" cy="11" r="11" fill="url(#radial_0)" />
      <circle cx="11" cy="11" r="6" fill={stopColors[0]} />
      <defs>
        <radialGradient
          id="radial_0"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11 11) rotate(90) scale(11)"
        >
          <stop stopColor={stopColors[2]} stopOpacity="0.78" />
          <stop offset="0.744792" stopColor={stopColors[1]} stopOpacity="0.34" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

const CustomActiveDot = (props: DotProps & { numDataPoints: number }) => {
  const { cx, cy, numDataPoints } = props;
  if (numDataPoints === 1) {
    return <NullDot />;
  }

  return <Dot cx={cx} cy={cy} r={8} fill={hoverDotColor} stroke="none" />;
};

/**
 * At either end of the highlight line, render a dot. Based on the value of the `hasMomentum` prop, the dots will be
 * shaded with a color to signal either momentum or possible delay.
 */
const HighlightedLineDot = (
  props: DotProps & { hasMomentum?: boolean; startDate: number; endDate: number; payload: { value: number } }
) => {
  const { cx, cy, hasMomentum, startDate, endDate, payload } = props;

  if (![startDate, endDate].includes(payload.date)) {
    return null;
  }

  if (hasMomentum === true) {
    return <HighlightDot cx={cx} cy={cy} variant="momentum" />;
  }
  if (hasMomentum === false) {
    return <HighlightDot cx={cx} cy={cy} variant="warning" />;
  }

  return <HighlightDot cx={cx} cy={cy} />;
};

/**
 * Renderer for the dots in the line chart. If there is only one data point in the graph, we render a single dot. If
 * there are multiple points in the graph, we render no dots (`NullDot`).
 */
const CustomDot = (props: DotProps & { numDataPoints: number }) => {
  if (props.numDataPoints === 1) {
    return <HighlightDot cx={props.cx} cy={props.cy} />;
  }

  return <NullDot />;
};

/**
 * Using this rather than providing a React Fragment to the dots that we don't want to render in the lines.
 * Recharts has some weird behavior here - if a Dot component isn't provided (or if undefined is explicitly provided) it
 * renders the default Dot, which we don't want.
 */
const NullDot = () => {
  return null;
};

const yAxisTickFormatter = (value: number) => (value > 0 ? `${value}%` : '');

export const ProgressChart = (props: ProgressChartProps) => {
  const {
    baselineCompletionDate,
    baselineStartDate,
    data,
    hasMomentum,
    highlightInterval = 30,
    highlightLimit = 0,
    highlightLimitMaxDate = new Date(),
    highlightMode = 'duration',
    minDurationToHighlight = 30,
    minHeight = '25rem',
    noDataMessage = 'No data yet',
  } = props;

  const highlightColor = useMemo(() => {
    return hasMomentum === true
      ? momentumHighlightColor
      : hasMomentum === false
      ? warningHighlightColor
      : defaultHighlightColor;
  }, [hasMomentum]);

  if (data.length === 0) {
    return (
      <Flex
        alignItems="center"
        backgroundColor={theme.colors.brand.gray[50]}
        borderRadius={theme.radii.card}
        color={theme.colors.brand.gray[600]}
        height="22rem"
        justifyContent="center"
        padding="1rem"
        width="100%"
      >
        {noDataMessage}
      </Flex>
    );
  }

  const getXAxisTicks = () => {
    const firstProgressPointDate = new Date(data[0].date).getTime();
    const ticks: number[] = [];

    if (baselineStartDate !== undefined && isBefore(baselineStartDate, firstProgressPointDate)) {
      ticks.push(baselineStartDate);
    } else {
      ticks.push(firstProgressPointDate);
    }

    if (data.length === 1) {
      return ticks;
    }

    const lastProgressPointDate = new Date(data[data.length - 1].date).getTime();

    if (baselineCompletionDate !== undefined && isAfter(baselineCompletionDate, lastProgressPointDate)) {
      ticks.push(baselineCompletionDate);
    } else {
      ticks.push(lastProgressPointDate);
    }

    return ticks;
  };

  /**
   * Set the domain of the X-axis. The minimum value for this chart's domain should always be the earliest walkthrough
   * date (minimum data point). The maximum value for the domain is either the latest walkthrough date, or the baseline
   * completion date, depending on which one is later in time.
   */
  const domain: AxisDomain = [
    (dataMin: number) => dataMin,
    (dataMax: number) => Math.max(dataMax, baselineCompletionDate ?? 0),
  ];

  /** Get the set of X-axis ticks. These are important points on the chart. */
  const ticks = getXAxisTicks();

  const highlightedLineData: ChartPoint[] = [];
  const canHighlight = differenceInCalendarDays(data[data.length - 1].date, data[0].date) >= minDurationToHighlight;

  if (canHighlight && highlightMode === 'duration') {
    // filter the data to the dates below the highlightLimitMaxDate
    const dataBelowLimit = data.filter((walkthrough) => isBefore(walkthrough.date, endOfDay(highlightLimitMaxDate)));

    for (let i = dataBelowLimit.length - 1; i >= 0; i--) {
      if (
        differenceInCalendarDays(dataBelowLimit[dataBelowLimit.length - 1].date, dataBelowLimit[i].date) >=
        highlightInterval
      ) {
        break;
      }

      highlightedLineData.push(data[i]);
    }
  }
  if (canHighlight && highlightMode === 'limit') {
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].date > highlightLimitMaxDate.getTime()) {
        continue;
      }

      highlightedLineData.push(data[i]);
      if (highlightedLineData.length === highlightLimit) {
        break;
      }
    }
  }

  const hasBaselineCompletionDate = Boolean(baselineCompletionDate);
  const hasBaselineStartDate = Boolean(baselineStartDate);

  const CustomXAxisTick = (props: { x: number; y: number; payload: { value: number } }) => {
    const { x, y, payload } = props;

    const date = new Date(payload.value);

    const minValue = baselineStartDate !== undefined ? min([data[0].date, baselineStartDate]) : data[0].date;
    const maxValue =
      baselineCompletionDate !== undefined
        ? max([data[data.length - 1].date, baselineCompletionDate])
        : data[data.length - 1].date;

    if (minValue || maxValue) {
      return (
        <>
          <text x={x} y={y + 16} fill={xAxisTextColor} textAnchor="middle">
            {format(date, 'MMM d')}
          </text>
          <text x={x} y={y + 32} fill={xAxisTextColor} textAnchor="middle">
            {format(date, 'yyyy')}
          </text>
        </>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%" minWidth="25rem" minHeight={minHeight}>
      <LineChart data={data} margin={{ top: 12, left: -12, bottom: 12, right: 24 }}>
        <CartesianGrid stroke={gridStrokeColor} vertical={false} />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          scale="time"
          type="number"
          domain={domain}
          tick={(props) => <CustomXAxisTick {...props} />}
          ticks={ticks}
          tickCount={2}
          interval={0}
          minTickGap={20}
          allowDuplicatedCategory={false}
        />
        <YAxis
          dataKey="value"
          tickCount={5}
          domain={[0, 100]}
          axisLine={false}
          tickLine={false}
          tick={{ fill: yAxisTextColor }}
          tickFormatter={yAxisTickFormatter}
        />
        <Line
          isAnimationActive={false}
          dataKey="value"
          data={data}
          stroke={lineStrokeColor}
          strokeWidth={lineStrokeWidth}
          dot={(props) => <CustomDot {...props} numDataPoints={data.length} />}
          activeDot={(props) => <CustomActiveDot {...props} numDataPoints={data.length} />}
          name="progressHistory"
        />
        {highlightedLineData.length > 0 && (
          <Line
            isAnimationActive={false}
            dataKey="value"
            data={highlightedLineData}
            strokeWidth={4}
            stroke={highlightColor}
            dot={(props) => (
              <HighlightedLineDot
                {...props}
                hasMomentum={hasMomentum}
                startDate={highlightedLineData[0].date}
                endDate={highlightedLineData[highlightedLineData.length - 1].date}
              />
            )}
            activeDot={false}
            name="highlightedLineData"
            tooltipType="none"
          />
        )}
        {!hasBaselineStartDate && hasBaselineCompletionDate && (
          <Line
            isAnimationActive={false}
            dataKey="value"
            data={[
              { date: data[0].date, value: data[0].value },
              { date: baselineCompletionDate, value: 100 },
            ]}
            strokeWidth={2}
            stroke={theme.colors.brand.gray[200]}
            strokeDasharray="6 2"
            dot={false}
            activeDot={false}
            name="baselineCompletion"
          />
        )}
        {hasBaselineStartDate && hasBaselineCompletionDate && (
          <Line
            isAnimationActive={false}
            dataKey="value"
            // TODO: clean up, use a function to get the data for this
            data={[
              { date: baselineStartDate, value: 0 },
              { date: baselineCompletionDate, value: 100 },
            ]}
            strokeWidth={2}
            stroke={theme.colors.brand.gray[200]}
            strokeDasharray="6 2"
            dot={false}
            activeDot={false}
            name="baseline"
          />
        )}
        <Tooltip
          content={({ payload, label }) => {
            const progressValue = payload?.find((point) => point.name === 'progressHistory')?.value;
            const baselineValue = payload?.find((point) => point.name === 'baselineCompletion')?.value;

            if (payload && label && label !== 'baselineCompletion') {
              const date = new Date(label);
              return (
                <ChartTooltip
                  label={format(date, 'MMM d, yyyy')}
                  value={progressValue as number}
                  baselineValue={baselineValue as number}
                />
              );
            }
            return null;
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
