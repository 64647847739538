import { AxiosRequestConfig } from 'axios';

import { get, post } from '../../next';

/** Response body shape returned after requesting an authentication redirect URL for a given Nylas provider. */
export interface NylasAuthenticationUrlResponse {
  authentication_url: string;
}

/**
 * Request body shape used to submit a provider's authentication code to Core API (and through it, Nylas) to complete
 * the provider authentication workflow.
 */
export interface NylasAuthenticationCodeRequestBody {
  code: string;
}

/** Currently supported Nylas connectors. For now, we only support Google. Later on, we'll add more. */
export const NylasProvider = {
  GOOGLE: 'google',
};

const NylasIntegrationApi = {
  /**
   * Retrieve a Nylas authentication redirect URL. The user should be sent to the returned URL in order to authenticate
   * with an email provider.
   * @param provider The Nylas provider name used to begin the authentication workflow. The typing for this does not
   * require the parameter to match the values of `NylasProvider` so that queries may fall back on `''` while disabled.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the authentication redirect URL response.
   */
  getNylasAuthenticationUrl: (provider: string, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    queryParams.set('provider', provider);

    return get<NylasAuthenticationUrlResponse>(
      `api/v1/integrations/nylas/authentication-url/?${queryParams.toString()}`,
      requestConfig
    );
  },
  /**
   * Complete the authentication process for a Nylas provider. When the user is redirected to the configured
   * authentication callback page, the URL will include a query parameter called `code`. This request then submits the
   * code to Core API. Behind the scenes, Core API will use the code to obtain a Nylas grant ID.
   * @param code The provider's code to be exchanged for a Nylas grant ID by Core API.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping an empty response (on success).
   */
  submitAuthenticationCode: (code: string, requestConfig?: AxiosRequestConfig) => {
    const requestBody: NylasAuthenticationCodeRequestBody = { code };
    return post<NylasAuthenticationCodeRequestBody>(
      'api/v1/integrations/nylas/authentication-code/',
      requestBody,
      requestConfig
    );
  },
};

export default NylasIntegrationApi;
