import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Store } from '../@types/redux/store';
import { attemptLoadMe } from '../actions/auth';
import { getAuthToken as getProcoreAuth } from '../actions/procore';
import { UserManagementApi } from '../api/next';
import { BaseLayout, LoadingIndicator } from '../components';
import UserProfileCompletionModal from '../components/UserProfile/UserProfileCompletionModal';
import { QueryTopics, UserManagementQueryKeys } from '../constants/queries';
import { AccountUsersContainer } from '../pages/AccountUsers/AccountUsersContainer';
import FloorplanContainer from '../pages/Floorplan/FloorplanContainer';
import PortfolioDashboardContainer from '../pages/PortfolioDashboard/PortfolioDashboardContainer';
import ProgressTrackingContainer from '../pages/ProgressTracking/ProgressTrackingContainer';
import SettingsContainer from '../pages/Settings/SettingsContainer';
import View360Container from '../pages/View360/View360Container';
import WalkthroughContainer from '../pages/Walkthrough/WalkthroughContainer';
import LegacyProjectUrlRedirectContainer from '../redirects/LegacyProjectUrlRedirectContainer';
import NylasCallbackRedirectContainer from '../redirects/NylasCallbackRedirectContainer';
import Routes from '../routes';
import PageNotFound from './404';
import AuthenticatedRoute from './AuthenticatedRoute';
import Login from './Login';
import Onboarding from './Onboarding';
import ProcoreCallback from './ProcoreCallback';
import SetFirstPassword from './SetFirstPassword';
import UnauthenticatedRoute from './UnauthenticatedRoute';

const App = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state: Store) => state.auth);

  const queryClient = useQueryClient();

  useEffect(() => {
    async function computeAuthState() {
      const user = await attemptLoadMe(dispatch);
      if (user) {
        dispatch(getProcoreAuth());
      }
    }

    computeAuthState();
  }, [dispatch]);

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => {
      return (await UserManagementApi.getMe()).data;
    },
    enabled: authState.isAuthenticated,
  });

  useEffect(() => {
    // We don't want to report internal users
    if (authState?.user?.is_internal === false) {
      // We want to avoid mixing up local/dev data with prod.
      // We probably should have a separate intercom workspace, but we don't.
      const envPrefix = process.env.REACT_APP_ENVIRONMENT !== 'production' ? 'TEST-' : '';
      const companies = meQuery?.data?.access_account_projects_subset?.map((c) => ({
        id: `${envPrefix}${c.id}`,
        name: `${envPrefix}${c.name}`,
      }));
      window.Intercom?.('update', {
        companies,
      });
    }
  }, [meQuery.data, authState]);

  // On logout, clear all cached query state.
  useEffect(() => {
    if (!authState.isAuthenticated) {
      queryClient.clear();
    }
  }, [authState.isAuthenticated, queryClient]);

  if (!authState.loaded) {
    return (
      <BaseLayout>
        <LoadingIndicator fullPage />
      </BaseLayout>
    );
  }

  return (
    <>
      <UserProfileCompletionModal />
      <Switch>
        <UnauthenticatedRoute exact path={Routes.LOGIN_CALLBACK} component={Login} />
        <UnauthenticatedRoute path={Routes.LOGIN} component={Login} />
        <UnauthenticatedRoute path={Routes.FORGOT_PASSWORD} component={Login} />
        <UnauthenticatedRoute path={Routes.RESET_PASSWORD} component={Login} />
        <UnauthenticatedRoute path={Routes.ONBOARDING} component={Onboarding} />
        <AuthenticatedRoute path={Routes.LOGIN_PROCORE_CALLBACK} component={ProcoreCallback} />
        <AuthenticatedRoute path={Routes.SET_PASSWORD} component={SetFirstPassword} />
        <AuthenticatedRoute exact path={Routes.PROJECTS} component={PortfolioDashboardContainer} />
        <Redirect exact path={Routes.LEGACY_PROJECT_DASHBOARD} to={Routes.PROJECT_PROGRESS_TRACKING} />
        <Redirect exact path={Routes.LEGACY_PROJECT_DETAILS} to={Routes.PROJECT_PROGRESS_TRACKING} />
        <Redirect exact path={Routes.LEGACY_PROJECT_EXECUTIVE_DASHBOARD} to={Routes.PROJECT_PROGRESS_TRACKING} />
        <Redirect exact path={Routes.LEGACY_PROJECT_ISSUES_REPORT} to={Routes.PROJECT_PROGRESS_TRACKING} />
        <Redirect exact path={Routes.LEGACY_PROJECT_MILESTONE_INSIGHTS} to={Routes.PROJECT_PROGRESS_TRACKING} />
        <AuthenticatedRoute exact path={Routes.PROJECT_PROGRESS_TRACKING} component={ProgressTrackingContainer} />
        <AuthenticatedRoute path={Routes.VIEW_360} component={View360Container} />
        <AuthenticatedRoute path={Routes.WALKTHROUGH} component={WalkthroughContainer} />
        <AuthenticatedRoute path={Routes.FLOORPLAN} component={FloorplanContainer} />
        <AuthenticatedRoute exact path={Routes.ACCOUNT_USERS} component={AccountUsersContainer} />
        <AuthenticatedRoute path={Routes.LEGACY_VIEW_360} component={LegacyProjectUrlRedirectContainer} />
        <AuthenticatedRoute path={Routes.LEGACY_FLOORPLAN} component={LegacyProjectUrlRedirectContainer} />
        <AuthenticatedRoute path={Routes.LEGACY_FLOORPLAN_EMPTY} component={LegacyProjectUrlRedirectContainer} />
        <AuthenticatedRoute path={Routes.SETTINGS} component={SettingsContainer} />
        <AuthenticatedRoute path={Routes.NYLAS_AUTHENTICATION_CALLBACK} component={NylasCallbackRedirectContainer} />
        <Route path={Routes.NOT_FOUND} component={PageNotFound} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </>
  );
};

export default App;
