import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns';

import { FloorplanApi } from '../../api/v1/rest/FloorplanApi';
import { PendoTopic } from '../../constants/analytics';
import { ProgressTrackingQueryKeys, ProjectQueryKeys, QueryTopics } from '../../constants/queries';
import Toast from '../Toast';
import { BaselineDates } from './BaselineDates';

interface FloorplanBaselineDatesContainerProps {
  canEditDates: boolean;
  completionDate: Date | null;
  startDate: Date | null;
  projectId: number;
  floorplanId: number;
}

export const FloorplanBaselineDatesContainer = (props: FloorplanBaselineDatesContainerProps) => {
  const { canEditDates, completionDate, startDate, projectId, floorplanId } = props;

  const queryClient = useQueryClient();
  const toast = useToast();

  const updateDatesMutation = useMutation({
    mutationKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_FLOORPLAN_DATE_UPDATE, projectId, floorplanId],
    mutationFn: ({ endDate, startDate }: { endDate?: string | null; startDate?: string | null }) => {
      return FloorplanApi.updateFloorplanDates(floorplanId, {
        end_date: endDate,
        start_date: startDate,
      });
    },
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          if (error instanceof AxiosError && error.response?.status === 403) {
            return (
              <Toast
                {...props}
                title="Error"
                description="You do not have permission to set this date. Contact Customer Success for assistance."
                status="error"
              />
            );
          }
          if (error instanceof AxiosError && error.response?.status === 400) {
            return (
              <Toast
                {...props}
                title="Error"
                description="An error occurred. Please check the dates and try again."
                status="error"
              />
            );
          }
          return <Toast {...props} title="Error" description="An error occurred. Try again later." status="error" />;
        },
      });
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_HISTORY,
          projectId,
          // Intentionally omitted:
          // locationType,
          // location?.id,
        ],
        exact: false,
      });

      // Clear all Progress Tracking data for the project as well, just to ensure nothing is stale. This has to happen
      // because the BCDs for the root node and "All floorplans" mode are the synchronized, but also because data for
      // other Time Travel dates cannot be assumed to be valid anymore either, since the BCDs are used to calculate
      // momentum.
      queryClient.resetQueries({
        exact: false,
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_TABLE_DATA,
          projectId,
          // Intentionally omitted:
          // hierarchyNode?.id,
          // selectedDate,
        ],
      });

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Baseline date updated." status="success" />,
      });
    },
  });

  const handleCompletionDateChange = (date: Date | null) => {
    const newDate = date ? format(date, 'yyyy-MM-dd') : date;
    updateDatesMutation.mutate({ endDate: newDate });
  };

  const handleStartDateChange = (date: Date | null) => {
    const newDate = date ? format(date, 'yyyy-MM-dd') : date;
    updateDatesMutation.mutate({ startDate: newDate });
  };

  return (
    <BaselineDates
      boxProps={{ marginTop: '0.5rem' }}
      canEditDates={canEditDates}
      onCompletionDateChange={handleCompletionDateChange}
      pendoProps={{
        pendoTopic: PendoTopic.PROGRESS_TRACKING,
        parentComponent: 'Floorplan Drawer',
      }}
      isDateUpdating={updateDatesMutation.isLoading}
      completionDate={completionDate}
      startDate={startDate}
      onStartDateChange={handleStartDateChange}
    />
  );
};
