import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns';

import { PortfolioProject } from '../../@types/api/v1/bespoke/PortfolioDashboard';
import { UserManagementApi } from '../../api/next';
import { ProjectApi } from '../../api/v1/rest/ProjectApi';
import { PendoTopic } from '../../constants/analytics';
import { PortfolioDashboardQueryKeys, QueryTopics, UserManagementQueryKeys } from '../../constants/queries';
import Toast from '../Toast';
import { BaselineDates } from './BaselineDates';

interface ProjectBaselineDatesContainerProps {
  completionDate: Date | null;
  startDate: Date | null;
  project: PortfolioProject;
}

export const ProjectBaselineDatesContainer = (props: ProjectBaselineDatesContainerProps) => {
  const { completionDate, project, startDate } = props;

  const queryClient = useQueryClient();
  const toast = useToast();

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const updateDates = useMutation({
    mutationKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.DATES, project.id],
    mutationFn: ({
      projectId,
      startDate,
      endDate,
    }: {
      projectId: number;
      startDate?: string | null;
      endDate?: string | null;
    }) => ProjectApi.updateDates(projectId, { baseline_start_date: startDate, baseline_completion_date: endDate }),
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          if (error instanceof AxiosError && error.response?.status === 403) {
            return (
              <Toast
                {...props}
                title="Error"
                description="You do not have permission to set this date. Contact Customer Success for assistance."
                status="error"
              />
            );
          }
          if (error instanceof AxiosError && error.response?.status === 400) {
            return (
              <Toast
                {...props}
                title="Error"
                description="An error occurred. Please check the dates and try again."
                status="error"
              />
            );
          }
          return <Toast {...props} title="Error" description="An error occurred. Try again later." status="error" />;
        },
      });
    },
    onSuccess: (_, variables) => {
      const projectsQueryKey = [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.PORTFOLIO_PROJECTS_LIST];
      const projectData = queryClient.getQueryData<PortfolioProject[]>(projectsQueryKey) ?? [];
      const indexOfThisProjectInQueryData = projectData.findIndex((foundProject) => foundProject.id === project.id);
      if (indexOfThisProjectInQueryData === -1) {
        console.error('[ProjectBaselineDatesContainer] Project missing during baseline completion update');
        return;
      }
      const updatedProjectData = [
        ...projectData.slice(0, indexOfThisProjectInQueryData),
        { ...projectData[indexOfThisProjectInQueryData], baseline_completion_date: variables.endDate },
        ...projectData.slice(indexOfThisProjectInQueryData + 1),
      ];
      queryClient.setQueryData(projectsQueryKey, updatedProjectData);

      // Reset the query for the progress history for this project
      queryClient.resetQueries({
        queryKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.PROGRESS_HISTORY, project.id],
        exact: false,
      });

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => <Toast {...props} title="Success" description="Date updated." status="success" />,
      });
    },
  });

  const handleCompletionDateChange = (date: Date | null) => {
    const newDate = date ? format(date, 'yyyy-MM-dd') : date;
    updateDates.mutate({ projectId: project.id, endDate: newDate });
  };

  const handleStartDateChange = (date: Date | null) => {
    const newDate = date ? format(date, 'yyyy-MM-dd') : date;
    updateDates.mutate({ projectId: project.id, startDate: newDate });
  };

  const canEditDates = (meQuery.data?.set_trade_dates ?? []).some((account) => account.id === project.account_id);

  return (
    <BaselineDates
      boxProps={{ marginTop: '0.5rem' }}
      canEditDates={canEditDates}
      onCompletionDateChange={handleCompletionDateChange}
      pendoProps={{
        pendoTopic: PendoTopic.PORTFOLIO,
        parentComponent: 'Project Drawer',
      }}
      isDateUpdating={updateDates.isLoading}
      completionDate={completionDate}
      startDate={startDate}
      onStartDateChange={handleStartDateChange}
    />
  );
};
