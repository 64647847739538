import { Button, Divider, Flex, Icon, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../@types/redux/store';
import { clearProcoreIntegration } from '../../actions/procore';
import { LogoIcon, ProcoreIcon } from '../../components/Icon';
import { PendoTopic } from '../../constants/analytics';
import NylasAuthenticationModalContainer from '../../dialogs/NylasAuthenticationModal/NylasAuthenticationModalContainer';
import theme from '../../theme';
import ProcoreIntegrationModalContainer from './ProcoreIntegrationModal/ProcoreIntegrationModalContainer';

export interface IntegrationSettingsProps {
  /** Flag indicating whether or not the Context feature is available to the current user. */
  isContextAvailable?: boolean;
  /** Flag indicating whether or not the user has authenticated with an email provider for the Context feature. */
  isContextIntegrated?: boolean;
}

const IntegrationSettings = (props: IntegrationSettingsProps) => {
  const { isContextAvailable, isContextIntegrated } = props;

  const dispatch = useDispatch();
  const procoreToken = useSelector((state: Store) => state.auth.procoreToken);

  const {
    isOpen: isNylasAuthenticationModalOpen,
    onOpen: onOpenNylasAuthenticationModal,
    onClose: onCloseNylasAuthenticationModal,
  } = useDisclosure();
  const {
    isOpen: isProcoreIntegrationModalOpen,
    onOpen: onOpenProcoreIntegrationModalOpen,
    onClose: onCloseProcoreIntegrationModalOpen,
  } = useDisclosure();

  return (
    <>
      <Flex
        alignItems="center"
        aria-label="OnsiteIQ Context"
        justifyContent="space-between"
        paddingBlock="0.5rem"
        role="region"
      >
        <VStack alignItems="flex-start">
          <Icon
            aria-hidden
            as={LogoIcon}
            color={theme.colors.brand.primary[600]}
            height="auto"
            // Negative spacing here to account for icon offset from viewbox edges.
            marginLeft="-0.375rem"
            marginBottom="-0.5rem"
            width="4rem"
          />
          <Text>OnsiteIQ Context (Email Access)</Text>
        </VStack>
        {!isContextAvailable && <Text color={theme.colors.brand.gray[600]}>Coming soon</Text>}
        {isContextAvailable && !isContextIntegrated && (
          <Button
            data-pendo-label="Authorize access"
            data-pendo-topic={PendoTopic.CONTEXT}
            onClick={onOpenNylasAuthenticationModal}
            padding="0.5rem 1.25rem"
            size="md"
            variant="lowEmphasisV2"
          >
            Connect
          </Button>
        )}
        {isContextAvailable && isContextIntegrated && (
          // TODO: TS-1597
          <Button
            data-pendo-label="Revoke access"
            data-pendo-topic={PendoTopic.CONTEXT}
            onClick={() => console.debug('STUB: revoke email account access OIQ Context')}
            padding="0.5rem 1.25rem"
            size="md"
            variant="lowEmphasisV2"
          >
            Disconnect
          </Button>
        )}
      </Flex>
      <Divider margin="0.75rem 0" />
      <Flex alignItems="center" aria-label="Procore" justifyContent="space-between" paddingBlock="0.5rem" role="region">
        <VStack>
          <Icon aria-hidden as={ProcoreIcon} height="2.625rem" width="2.875rem" />
          <Text>Procore</Text>
        </VStack>
        {procoreToken ? (
          <Button
            onClick={() => dispatch(clearProcoreIntegration())}
            padding="0.5rem 1.25rem"
            size="md"
            variant="lowEmphasisV2"
          >
            Disconnect
          </Button>
        ) : (
          <Button
            data-pendo-label="Procore connect"
            data-pendo-topic="social"
            onClick={onOpenProcoreIntegrationModalOpen}
            padding="0.5rem 1.25rem"
            size="md"
            variant="lowEmphasisV2"
          >
            Connect
          </Button>
        )}
      </Flex>
      <NylasAuthenticationModalContainer
        isOpen={isNylasAuthenticationModalOpen}
        onClose={onCloseNylasAuthenticationModal}
      />
      <ProcoreIntegrationModalContainer
        isOpen={isProcoreIntegrationModalOpen}
        onClose={onCloseProcoreIntegrationModalOpen}
      />
    </>
  );
};

export default IntegrationSettings;
