import { AxiosRequestConfig } from 'axios';

import { patch } from '../../next';

/**
 * Request shape used when updating milestone-related dates for a hierarchy node. The only dates that are currently
 * supported are baseline completion dates, i.e. end dates.
 */
export interface HierarchyNodeMilestoneDateUpdateRequestBody {
  /** ID of the milestone. */
  milestone_id: number;
  /**
   * Projected date on which the given milestone/trade is expected to be completed for the hierarchy node. This is the
   * date portion (i.e. everything up to the "T") of a RFC 3339 timestamp.
   *
   * It may also be null, which will unset the date.
   *
   * @example "2024-10-18"
   */
  end_date?: string | null;
  start_date?: string | null;
}

/**
 * Response shape returned when a milestone's dates are successfully set for a hierarchy node. Note that this is
 * currently the same shape as the request.
 */
export type HierarchyNodeMilestoneDateUpdateResponseBody = HierarchyNodeMilestoneDateUpdateRequestBody;

export interface HierarchyNodeDateUpdateRequestBody {
  /**
   * Projected date on which the given hierarchy node is expected to be completed. This is the
   * date portion (i.e. everything up to the "T") of a RFC 3339 timestamp.
   *
   * @example "2024-10-18"
   */
  end_date: string | null;
}

/**
 * Response shape returned when a baseline completion date for a hierarchy node is successfully set. Note that this is
 * currently the same shape as the request.
 */
export type HierarchyNodeDateUpdateResponseBody = HierarchyNodeDateUpdateRequestBody;

export interface HierarchyNodeDatesUpdateRequestBody {
  /**
   * Projected date on which the given hierarchy node is expected to be completed. This is the
   * date portion (i.e. everything up to the "T") of a RFC 3339 timestamp.
   *
   * @example "2024-10-18"
   */
  end_date?: string | null;
  start_date?: string | null;
}

/**
 * Response shape returned when a baseline completion date for a hierarchy node is successfully set. Note that this is
 * currently the same shape as the request.
 */
export type HierarchyNodeDatesUpdateResponseBody = HierarchyNodeDatesUpdateRequestBody;

export const HierarchyNodeApi = {
  /**
   * Update a milestone/trade date entry for a given hierarchy node.
   * @param hierarchyNodeId The ID of the hierarchy node.
   * @param requestBody The milestone ID and date combination to set on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the updated hierarchy node's milestone date entry.
   */
  updateMilestoneDate: (
    hierarchyNodeId: number,
    requestBody: HierarchyNodeMilestoneDateUpdateRequestBody,
    requestConfig?: AxiosRequestConfig
  ) =>
    patch<HierarchyNodeMilestoneDateUpdateRequestBody, HierarchyNodeMilestoneDateUpdateResponseBody>(
      `api/v1/rest/hierarchy-nodes/${hierarchyNodeId}/milestone-dates/`,
      requestBody,
      requestConfig
    ),
  /**
   * Update a baseline date entry for a given hierarchy node.
   * @param hierarchyNodeId The ID of the hierarchy node.
   * @param requestBody The date(s) to set on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the updated hierarchy node's dates entry.
   */
  updateHierarchyNodeDates: (
    hierarchyNodeId: number,
    requestBody: HierarchyNodeDatesUpdateRequestBody,
    requestConfig?: AxiosRequestConfig
  ) =>
    patch<HierarchyNodeDatesUpdateRequestBody, HierarchyNodeDatesUpdateResponseBody>(
      `api/v1/rest/hierarchy-nodes/${hierarchyNodeId}/dates/`,
      requestBody,
      requestConfig
    ),
};
