import { BoxProps, ButtonGroup, Icon, IconButton, Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react';
import { add, format } from 'date-fns';

import { PendoTopic } from '../../constants/analytics';
import theme from '../../theme';
import DatePicker from '../DatePicker/DatePicker';
import { DeleteIcon } from '../Icon';

interface BaselineDatesProps {
  /** Props to pass to the outermost element (technically a StatGroup, but still a wrapper div). */
  boxProps?: BoxProps;
  /** True if the user has permission to edit dates. */
  canEditDates: boolean;
  /** The completion/end date to display/set. */
  completionDate: Date | null;
  /** True when either the start or end date is updating. */
  isDateUpdating?: boolean; // TODO: split this into two updating/loading states?
  /** Handler to call when the user selects a new completion date. */
  onCompletionDateChange: (newDate: Date | null) => void;
  /** Handler to call when the user selects a new start date */
  onStartDateChange: (newDate: Date | null) => void;
  /** Pendo topic and the name of the containing/parent component for use in the Pendo label, both used for the interactive date picker elements. */
  pendoProps: {
    /** The name of the parent component. */
    parentComponent: 'Project Drawer' | 'Floorplan Drawer' | 'Hierarchy Drawer' | 'Milestone Drawer';
    /** The Pendo topic (page name). */
    pendoTopic: PendoTopic;
  };
  /** The start date to display/set. */
  startDate: Date | null;
}

export const BaselineDates = (props: BaselineDatesProps) => {
  const {
    boxProps,
    canEditDates,
    completionDate,
    isDateUpdating,
    onCompletionDateChange,
    onStartDateChange,
    pendoProps: { pendoTopic, parentComponent },
    startDate,
  } = props;

  return (
    <StatGroup {...boxProps}>
      <Stat>
        <StatLabel id="baseline-start-date">Baseline Start</StatLabel>
        <StatNumber
          aria-labelledby="baseline-start-date"
          whiteSpace="nowrap"
          fontSize={canEditDates ? 'initial' : '1.25rem'}
          marginTop="0.5rem"
        >
          {!canEditDates && !startDate && '-'}
          {!canEditDates && startDate && format(startDate, 'EEE, MMM d, yyyy')}
          {canEditDates && (
            <ButtonGroup isAttached={Boolean(startDate)}>
              <DatePicker
                buttonProps={{
                  'aria-label': 'Baseline Start Date',
                  'data-pendo-label': `Clicked Baseline Start Date from ${parentComponent}`,
                  'data-pendo-topic': pendoTopic,
                  width: { base: '100%', lg: '8.5rem' },
                  minHeight: '2rem',
                  size: 'sm',
                  isLoading: isDateUpdating,
                  borderRadius: startDate ? '0.5rem 0 0 0.5rem' : '0.5rem',
                  _hover: {
                    // Hack: this is staight from the theme. This overriding doesn't appear to be additive.
                    backgroundColor: 'transparent',
                    borderColor: 'var(--gray-500)',
                    zIndex: 1,
                  },
                }}
                icon="CalendarIcon"
                maxDate={add(new Date(), { years: 10 })}
                onChange={(newDate: Date) => onStartDateChange(newDate)}
                popoverPlacement="bottom-end"
                popoverVariant="searchInput"
                selected={startDate}
                value={startDate ? format(startDate, 'MMM d, yyyy') : 'Select a date'}
              />
              {Boolean(startDate) && (
                <IconButton
                  aria-label="Unset baseline start date"
                  borderRadius="0 0.5rem 0.5rem 0"
                  color={theme.colors.brand.gray[600]}
                  marginLeft="-1px"
                  data-pendo-label="Clear baseline start date"
                  data-pendo-topic={pendoTopic}
                  isDisabled={isDateUpdating}
                  height="2rem"
                  icon={<Icon as={DeleteIcon} height="1.25rem" width="1.25rem" />}
                  onClick={() => onStartDateChange(null)}
                  variant="outline"
                  minHeight="2rem"
                  size="sm"
                  _hover={{
                    // Hack: this is staight from the theme. This overriding doesn't appear to be additive.
                    backgroundColor: 'transparent',
                    borderColor: 'var(--gray-500)',
                    color: theme.colors.brand.error[500],
                    zIndex: 1,
                  }}
                />
              )}
            </ButtonGroup>
          )}
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel id="baseline-completion-date">Baseline Completion</StatLabel>
        <StatNumber
          aria-labelledby="baseline-completion-date"
          whiteSpace="nowrap"
          fontSize={canEditDates ? 'initial' : '1.25rem'}
          marginTop="0.5rem"
        >
          {!canEditDates && !completionDate && '-'}
          {!canEditDates && completionDate && format(completionDate, 'EEE, MMM d, yyyy')}
          {canEditDates && (
            <ButtonGroup isAttached={Boolean(completionDate)}>
              <DatePicker
                buttonProps={{
                  'aria-label': 'Baseline Completion Date',
                  'data-pendo-label': `Clicked Baseline Completion Date from ${parentComponent}`,
                  'data-pendo-topic': pendoTopic,
                  width: { base: '100%', lg: '8.5rem' },
                  minHeight: '2rem',
                  size: 'sm',
                  isLoading: isDateUpdating,
                  borderRadius: completionDate ? '0.5rem 0 0 0.5rem' : '0.5rem',
                  _hover: {
                    // Hack: this is staight from the theme. This overriding doesn't appear to be additive.
                    backgroundColor: 'transparent',
                    borderColor: 'var(--gray-500)',
                    zIndex: 1,
                  },
                }}
                icon="CalendarIcon"
                maxDate={add(new Date(), { years: 10 })}
                onChange={(newDate: Date) => onCompletionDateChange(newDate)}
                popoverPlacement="bottom-end"
                popoverVariant="searchInput"
                selected={completionDate}
                value={completionDate ? format(completionDate, 'MMM d, yyyy') : 'Select a date'}
              />
              {Boolean(completionDate) && (
                <IconButton
                  aria-label="Unset baseline completion date"
                  borderRadius="0 0.5rem 0.5rem 0"
                  color={theme.colors.brand.gray[600]}
                  marginLeft="-1px"
                  data-pendo-label="Clear baseline completion date"
                  data-pendo-topic={pendoTopic}
                  isDisabled={isDateUpdating}
                  height="2rem"
                  icon={<Icon as={DeleteIcon} height="1.25rem" width="1.25rem" />}
                  onClick={() => onCompletionDateChange(null)}
                  variant="outline"
                  minHeight="2rem"
                  size="sm"
                  _hover={{
                    // Hack: this is staight from the theme. This overriding doesn't appear to be additive.
                    backgroundColor: 'transparent',
                    borderColor: 'var(--gray-500)',
                    color: theme.colors.brand.error[500],
                    zIndex: 1,
                  }}
                />
              )}
            </ButtonGroup>
          )}
        </StatNumber>
      </Stat>
    </StatGroup>
  );
};
