import { useQuery } from '@tanstack/react-query';

import { AuthApi } from '../../api/v0/rest/AuthApi';
import PageController from '../../components/ControlCenter/PageController';
import { InternalLayout } from '../../components/Layout';
import Content from '../../components/Layout/Content';
import Pane from '../../components/Pane/Pane';
import PaneBody from '../../components/Pane/PaneBody';
import PaneHead from '../../components/Pane/PaneHead';
import { AuthQueryKeys, QueryTopics } from '../../constants/queries';
import IntegrationSettings from './IntegrationSettings';
import NotificationSettings from './NotificationSettings';
import ProfileSettings from './ProfileSettings';

const SettingsContainer = () => {
  const userProfileQuery = useQuery({
    queryKey: [QueryTopics.AUTH, AuthQueryKeys.USER_PROFILE],
    queryFn: async ({ signal }) => (await AuthApi.getUserProfile({ signal })).data,
  });

  /**
   * Whether or not the Context feature is available to the current user. For now, we'll only show this to internal
   * users. Once actual email ingestion and the revoke workflow are implemented, we can show this to customers.
   */
  const isContextAvailable = userProfileQuery.data?.is_internal;

  /** Whether or not the user has authenticated with an email provider for the Context feature. */
  const isContextIntegrated = userProfileQuery.data?.is_nylas_integrated;

  return (
    <InternalLayout>
      <Content applyGutters>
        <PageController title="Settings" />
        <Pane>
          <PaneHead headingLevel={2} title="Profile and Security" />
          <PaneBody>
            <ProfileSettings />
          </PaneBody>
        </Pane>
        <Pane>
          <PaneHead headingLevel={2} title="App Integrations" />
          <PaneBody>
            <IntegrationSettings isContextAvailable={isContextAvailable} isContextIntegrated={isContextIntegrated} />
          </PaneBody>
        </Pane>
        <Pane>
          <PaneHead headingLevel={2} title="Notification Preferences" />
          <PaneBody>
            <NotificationSettings />
          </PaneBody>
        </Pane>
      </Content>
    </InternalLayout>
  );
};

export default SettingsContainer;
