import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { Project } from '../../../@types/api/v0/rest/Project';
import { ProgressTrackingHierarchyNodeProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { ProjectHierarchyNode } from '../../../@types/api/v1/bespoke/ProjectHierarchy';
import { UserManagementApi } from '../../../api/next';
import { ProgressTrackingApi } from '../../../api/v1/bespoke/ProgressTrackingApi';
import { ProgressTrackingQueryKeys, QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import { HierarchyProgressDrawer } from './HierarchyProgressDrawer';

export interface HierarchyProgressDrawerContainerProps {
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current project. */
  project: Project;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** The hierarchy node to display in the drawer. */
  hierarchyNode?: ProjectHierarchyNode;
  /** Optional function to call when a hierarchy node is selected in the drawer. */
  onHierarchyNodeSelect?: (hierarchyNode?: ProjectHierarchyNode) => void;
  /** Flag indicating whether or not the hierarchy node has momentum. */
  hasMomentum?: boolean | null;
}

const HierarchyProgressDrawerContainer = (props: HierarchyProgressDrawerContainerProps) => {
  const { isOpen, onClose, project, selectedDate, hierarchyNode, onHierarchyNodeSelect, hasMomentum } = props;

  const location: ProjectHierarchyNode | undefined = hierarchyNode;
  const locationType = 'HIERARCHY_NODE_TOTALS';

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const queryKey = [
    QueryTopics.PROGRESS_TRACKING,
    ProgressTrackingQueryKeys.PROGRESS_HISTORY,
    project.id,
    locationType,
    location?.id,
    selectedDate,
  ];
  const progressHistoryQuery = useQuery({
    enabled: Boolean(isOpen),
    queryKey,
    queryFn: async ({ signal }) => {
      const promise: Promise<AxiosResponse<ProgressTrackingHierarchyNodeProgressHistory>> =
        ProgressTrackingApi.getTotalHierarchyNodeProgressHistory(hierarchyNode?.id ?? -1, {
          signal,
        });

      return (await promise).data;
    },
  });

  const canEditDates = (meQuery.data?.set_trade_dates ?? []).some((account) => account.id === project.account.id);

  const isProgressTrackingEnabled = project.executive_dashboard_enabled;

  const progressValue =
    progressHistoryQuery.data?.progress_values && progressHistoryQuery.data.progress_values.length > 0
      ? progressHistoryQuery.data?.progress_values[progressHistoryQuery.data.progress_values.length - 1].value
      : undefined;

  return (
    <HierarchyProgressDrawer
      canEditDates={canEditDates}
      hasMomentum={hasMomentum}
      isError={progressHistoryQuery.isError}
      isFetching={progressHistoryQuery.isFetching}
      isOpen={Boolean(isOpen)}
      isProgressTrackingEnabled={isProgressTrackingEnabled}
      progressHistory={progressHistoryQuery.data}
      onClose={onClose}
      selectedDate={selectedDate}
      progressValue={progressValue}
      hierarchyNode={hierarchyNode}
      onHierarchyNodeSelect={onHierarchyNodeSelect}
      projectId={project.id}
    />
  );
};

export default HierarchyProgressDrawerContainer;
