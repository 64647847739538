import { Box, Button, Flex, Icon, StatGroup, Text } from '@chakra-ui/react';
import { maxBy } from 'lodash';
import { MouseEvent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { ProgressTrackingMilestoneProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { ProjectHierarchyNode } from '../../../@types/api/v1/bespoke/ProjectHierarchy';
import { Milestone } from '../../../@types/api/v1/rest/Milestone';
import { MilestoneBaselineDatesContainer } from '../../../components/BaselineDates/MilestoneBaselineDatesContainer';
import { Drawer } from '../../../components/Drawer/Drawer';
import { Degree360Icon } from '../../../components/Icon';
import { MomentumReason } from '../../../components/ProgressChart/MomentumReason';
import { ChartPoint, ProgressChart } from '../../../components/ProgressChart/ProgressChart';
import { DurationStat } from '../../../components/Statistics/DurationStat';
import { EstimatedProgress } from '../../../components/Statistics/EstimatedProgress';
import { PercentChangeStat } from '../../../components/Statistics/PercentChangeStat';
import { DrawerType, PendoTopic } from '../../../constants/analytics';
import theme from '../../../theme';
import { generateProjectPageUrl } from '../../../utils/navigationUtils';
import { getRoundedProgressValue } from '../../../utils/progressUtils';
import { ProgressTrackingRowType } from '../ProgressTrackingPage';

export interface MilestoneProgressDrawerProps {
  /** Flag indicating whether or not the user is authorized to set the baseline dates for the milestone. */
  canEditDates: boolean;
  /** Flag indicating whether or not the floorplan/milestone combination has momentum. */
  hasMomentum?: boolean | null;
  /** Flag indicating whether or not a loading error has occurred. */
  isError?: boolean;
  /** Flag indicating whether or not data is fetching. */
  isFetching?: boolean;
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /**
   * Flag indicating whether or not Progress Tracking is enabled for this project. Even though this component is part of
   * the Progress Tracking page, the feature might not be enabled for the current project.
   */
  isProgressTrackingEnabled?: boolean;
  /** The current location. May be `undefined` when the drawer is closed or if a summary row cell is clicked. */
  location?: Floorplan | ProjectHierarchyNode;
  /**
   * If the row is not the summary row in "All floorplans" mode, this variable may be used to qualify the `location`.
   * Mainly useful for ensuring query key uniqueness.
   */
  locationType?: ProgressTrackingRowType.FLOORPLAN | ProgressTrackingRowType.HIERARCHY_NODE | undefined;
  /** The current milestone. May be `undefined` when the drawer is closed. */
  milestone?: Milestone;
  /**
   * An object containing summary statistics about the milestone (or milestone-floorplan combination) and a history of
   * progress values.
   */
  milestoneProgressHistory?: ProgressTrackingMilestoneProgressHistory;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** ID of the current project. */
  projectId: number;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** Progress value for either the current milestone or the current milestone-floorplan combination. */
  value?: number;
  /** ID of the walkthrough which generated the most recent progress entry. Provided for milestone-floorplan
   * combinations. This value is expected to be `undefined` when the drawer is closed or a summary row cell was clicked.
   */
  walkthroughId?: number;
}

const MilestoneProgressDrawer = (props: MilestoneProgressDrawerProps) => {
  const {
    canEditDates,
    hasMomentum,
    isError,
    isFetching,
    isOpen,
    isProgressTrackingEnabled,
    location,
    locationType,
    milestone,
    milestoneProgressHistory,
    onClose,
    projectId,
    selectedDate,
    value,
    walkthroughId,
  } = props;

  const history = useHistory();

  const errorContent = (
    <Text>
      An error occurred while loading the progress history for this location. Please try again later. If this issue
      persists, contact <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
    </Text>
  );

  const chartPoints = useMemo<ChartPoint[]>(() => {
    if (
      !isProgressTrackingEnabled ||
      !milestoneProgressHistory?.progress_values ||
      milestoneProgressHistory.progress_values.length === 0
    ) {
      return [];
    }

    const result: ChartPoint[] = [];
    for (const entry of milestoneProgressHistory.progress_values) {
      result.push({
        date: new Date(`${entry.date} 12:00:00`).getTime(),
        value: entry.value,
      });
    }

    return result;
  }, [isProgressTrackingEnabled, milestoneProgressHistory?.progress_values]);

  const isCompletionDateVisible = isProgressTrackingEnabled && Boolean(isOpen);

  const completionDate =
    isCompletionDateVisible && milestoneProgressHistory?.baseline_completion_date
      ? new Date(`${milestoneProgressHistory.baseline_completion_date} 12:00:00`)
      : undefined;

  const startDate =
    isCompletionDateVisible && milestoneProgressHistory?.start_date
      ? new Date(`${milestoneProgressHistory.start_date} 12:00:00`)
      : undefined;

  const bodyContent = (
    <>
      <Box>
        <EstimatedProgress progress={getRoundedProgressValue(value ?? 0)} progressBarSize="lg" />
        <StatGroup marginTop="1.5rem">
          <PercentChangeStat value={milestoneProgressHistory?.percent_change ?? null} />
          <DurationStat daysElapsed={milestoneProgressHistory?.days_elapsed} />
        </StatGroup>
        <MilestoneBaselineDatesContainer
          canEditDates={canEditDates}
          completionDate={completionDate ?? null}
          startDate={startDate ?? null}
          projectId={projectId}
          locationId={location?.id ?? -1}
          milestoneId={milestone?.id ?? -1}
          locationType={locationType}
        />
      </Box>
      <Flex
        borderBottom={`1px solid ${theme.colors.brand.gray[200]}`}
        marginBlockStart="1rem"
        paddingBottom="1.5rem"
        direction="column"
        alignItems="center"
      >
        <ProgressChart
          baselineCompletionDate={completionDate?.getTime()}
          baselineStartDate={startDate?.getTime()}
          data={chartPoints}
          hasMomentum={hasMomentum}
          highlightMode="duration"
          highlightInterval={14}
          highlightLimitMaxDate={selectedDate}
          minDurationToHighlight={14}
          minHeight="15rem"
          noDataMessage="📈 Data coming soon!"
        />
        {typeof hasMomentum === 'boolean' && (
          <MomentumReason hasMomentum={hasMomentum} hasCompletionDate={Boolean(completionDate)} />
        )}
      </Flex>
    </>
  );

  let goTo360Button;

  // If `location` has a `walkthroughs` key, it is a `Floorplan` and not a `ProjectHierarchyNode`.
  if (location && 'walkthroughs' in location) {
    const url = generateProjectPageUrl(
      projectId,
      location.id,
      walkthroughId ?? maxBy(location.dated_walkthroughs, 'when')?.id
    );
    goTo360Button = (
      <Button
        as="a"
        data-pendo-label="Go to 360 from Milestone Progress drawer"
        data-pendo-topic={PendoTopic.PROGRESS_TRACKING}
        flex="none"
        href={url}
        leftIcon={<Icon as={Degree360Icon} height="1.25rem" width="1.25rem" />}
        onClick={(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
          if (event.ctrlKey || event.metaKey) {
            return;
          }
          event.preventDefault();
          history.push(url);
        }}
        size="sm"
        variant="mediumEmphasisV2"
      >
        View 360
      </Button>
    );
  }

  return (
    <Drawer
      bodyContent={bodyContent}
      drawerType={DrawerType.MILESTONE_PROGRESS}
      errorContent={errorContent}
      isError={isError}
      isFetching={isFetching}
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      pendoTopic={PendoTopic.PROGRESS_TRACKING}
      primaryText={milestone?.name}
      secondaryText={location?.name}
      goTo360Button={goTo360Button}
    />
  );
};

export default MilestoneProgressDrawer;
