import { format, isValid } from 'date-fns';

interface FormattedDateSegments {
  formattedDate: string;
  formattedDateWithoutDay: string;
  fullMonthNoDay: string;
  formattedTime: string;
  formattedDateTime: string;
}

/**
 * Given an ISO 8601 date string, return an object containing the formatted date, time, and combined datetime. If the
 * date is not valid, returns undefined. Note that date-fns does not expose a simple timezone abbreviation -- it
 * prefers to express the timezone as an offset from GMT. Since it isn't readily available, retrieve it from a locale
 * string.
 */
export const formatIsoDate = (isoDate: string | undefined): FormattedDateSegments | undefined => {
  if (!isoDate) {
    return undefined;
  }

  const date = new Date(isoDate);
  if (!isValid(date)) {
    return undefined;
  }

  const formattedDate = format(date, 'EEE, MMM d, yyyy');
  const formattedDateWithoutDay = format(date, 'MMM d, yyyy');
  const fullMonthNoDay = format(date, 'MMMM d, yyyy');
  const timeZone = date.toLocaleString('en-us', { timeZoneName: 'short' }).split(' ').pop();
  const formattedTime = `${format(date, 'p')} ${timeZone}`;

  return {
    formattedDate,
    formattedTime,
    formattedDateWithoutDay,
    fullMonthNoDay,
    formattedDateTime: `${formattedDate} ${formattedTime}`,
  };
};
