import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Project } from '../../../@types/api/v0/rest/Project';
import { ProgressTrackingMilestoneProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { ProjectHierarchyNode } from '../../../@types/api/v1/bespoke/ProjectHierarchy';
import { Milestone } from '../../../@types/api/v1/rest/Milestone';
import { UserManagementApi } from '../../../api/next';
import { ProgressTrackingApi } from '../../../api/v1/bespoke/ProgressTrackingApi';
import { ProgressTrackingQueryKeys, QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import { ProgressTrackingRowType, ProgressTrackingTableRow } from '../ProgressTrackingPage';
import MilestoneProgressDrawer from './MilestoneProgressDrawer';

export interface MilestoneProgressDrawerContainerProps {
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /**
   * Flag indicating whether or not Progress Tracking is enabled for this project. Even though this component is part of
   * the Progress Tracking page, the feature might not be enabled for the current project.
   */
  isProgressTrackingEnabled?: boolean;
  /** The current milestone. May be `undefined` when the drawer is closed. */
  milestone?: Milestone;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current project. */
  project: Project;
  /** The current Progress Tracking table cell's parent row. May be `undefined` when the drawer is closed. */
  row?: ProgressTrackingTableRow;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
}

const MilestoneProgressDrawerContainer = (props: MilestoneProgressDrawerContainerProps) => {
  const { isOpen, isProgressTrackingEnabled, milestone, onClose, project, row, selectedDate } = props;

  const milestoneId = milestone && Number.isFinite(milestone.id) ? milestone.id : 0;

  /** If the row is not the summary row in "All floorplans" mode, this will either be a floorplan or hierarchy node. */
  let location: Floorplan | ProjectHierarchyNode | undefined;
  /**
   * If the row is not the summary row in "All floorplans" mode, this variable may be used to qualify the `location`.
   * Mainly useful for ensuring query key uniqueness.
   */
  let locationType: ProgressTrackingRowType.FLOORPLAN | ProgressTrackingRowType.HIERARCHY_NODE | undefined;
  let hasMomentum: boolean | null | undefined = undefined;
  let value: number | undefined;
  let walkthroughId: number | undefined = undefined;

  if (row?.rowType === ProgressTrackingRowType.FLOORPLAN) {
    location = row.floorplan;
    locationType = row.rowType;
    hasMomentum = row.cells[milestoneId]?.hasMomentum;
    value = row.cells[milestoneId]?.value;
    walkthroughId = row.cells[milestoneId]?.walkthroughId;
  }
  if (row?.rowType === ProgressTrackingRowType.HIERARCHY_NODE) {
    location = row.hierarchyNode;
    locationType = row.rowType;
    hasMomentum = row.cells[milestoneId]?.hasMomentum;
    value = row.cells[milestoneId]?.value;
  }
  if (row?.rowType === ProgressTrackingRowType.SUMMARY) {
    location = row.hierarchyNode;
    locationType = row.hierarchyNode ? ProgressTrackingRowType.HIERARCHY_NODE : undefined;
    hasMomentum = row.cells[milestoneId]?.hasMomentum;
    value = row.cells[milestoneId]?.value;
  }

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const queryKey = [
    QueryTopics.PROGRESS_TRACKING,
    ProgressTrackingQueryKeys.PROGRESS_HISTORY,
    project.id,
    milestoneId,
    locationType,
    location?.id,
  ];
  const progressHistoryQuery = useQuery({
    enabled: Boolean(isOpen) && milestoneId > 0,
    queryKey,
    queryFn: async ({ signal }) => {
      let promise: Promise<AxiosResponse<ProgressTrackingMilestoneProgressHistory>>;

      if (row?.rowType === ProgressTrackingRowType.FLOORPLAN) {
        promise = ProgressTrackingApi.getFloorMilestoneProgressHistory(project.id, milestoneId, row.floorplan.id, {
          signal,
        });
      } else if (row?.rowType === ProgressTrackingRowType.HIERARCHY_NODE) {
        promise = ProgressTrackingApi.getHierarchyNodeMilestoneProgressHistory(row.hierarchyNode.id, milestoneId, {
          signal,
        });
      } else if (row?.rowType === ProgressTrackingRowType.SUMMARY && row.hierarchyNode) {
        // Summary rows correspond to hierarchy nodes in hierarchy mode (i.e. not "All floorplans" mode).
        // This branch could have alternatively been an OR condition in the previous block, but this takes full
        // advantage of type narrowing/doesn't require a non-null assertion.
        promise = ProgressTrackingApi.getHierarchyNodeMilestoneProgressHistory(row.hierarchyNode.id, milestoneId, {
          signal,
        });
      } else {
        promise = ProgressTrackingApi.getTotalMilestoneProgressHistory(project.id, milestoneId, { signal });
      }

      return (await promise).data;
    },
  });

  const canEditDates = (meQuery.data?.set_trade_dates ?? []).some((account) => account.id === project.account.id);

  return (
    <MilestoneProgressDrawer
      canEditDates={canEditDates}
      hasMomentum={hasMomentum}
      isError={progressHistoryQuery.isError}
      isFetching={progressHistoryQuery.isFetching}
      isOpen={Boolean(isOpen)}
      isProgressTrackingEnabled={isProgressTrackingEnabled}
      location={location}
      locationType={locationType}
      milestone={milestone}
      milestoneProgressHistory={progressHistoryQuery.data}
      onClose={onClose}
      projectId={project.id}
      selectedDate={selectedDate}
      value={value}
      walkthroughId={walkthroughId}
    />
  );
};

export default MilestoneProgressDrawerContainer;
