import {
  Box,
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  StatGroup,
  Text,
} from '@chakra-ui/react';
import { maxBy } from 'lodash';
import { useMemo } from 'react';
import { useHistory } from 'react-router';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { ProgressTrackingFloorplanProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { LoadingIndicator } from '../../../components';
import { FloorplanBaselineDatesContainer } from '../../../components/BaselineDates/FloorplanBaselineDatesContainer';
import { Degree360Icon, DoubleRightArrowIcon } from '../../../components/Icon';
import { MomentumReason } from '../../../components/ProgressChart/MomentumReason';
import { ChartPoint, ProgressChart } from '../../../components/ProgressChart/ProgressChart';
import { DurationStat } from '../../../components/Statistics/DurationStat';
import { EstimatedProgress } from '../../../components/Statistics/EstimatedProgress';
import { PercentChangeStat } from '../../../components/Statistics/PercentChangeStat';
import { PendoTopic } from '../../../constants/analytics';
import theme from '../../../theme';
import { generateProjectPageUrl } from '../../../utils/navigationUtils';
import { getRoundedProgressValue } from '../../../utils/progressUtils';

export interface FloorplanProgressDrawerProps {
  /** Flag indicating whether or not the user is authorized to set the dates for the floorplan. */
  canEditDates: boolean;
  /** Flag indicating whether or not the floorplan has momentum. */
  hasMomentum?: boolean | null;
  /** Flag indicating whether or not a loading error has occurred. */
  isError?: boolean;
  /** Flag indicating whether or not data is fetching. */
  isFetching?: boolean;
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /**
   * Flag indicating whether or not Progress Tracking is enabled for this project. Even though this component is part of
   * the Progress Tracking page, the feature might not be enabled for the current project.
   */
  isProgressTrackingEnabled?: boolean;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current floorplan. */
  floorplan?: Floorplan;
  /** An object containing summary statistics about the floorplan and a history of progress values. */
  progressHistory?: ProgressTrackingFloorplanProgressHistory;
  /** Progress value for the floorplan. */
  progressValue?: number;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** The project ID for this floorplan. */
  projectId: number;
}

// This file will be reworked as part of TS-1578, so the complexity is fine for now.
// eslint-disable-next-line complexity
export const FloorplanProgressDrawer = (props: FloorplanProgressDrawerProps) => {
  const {
    canEditDates,
    hasMomentum,
    isError,
    isFetching,
    isOpen,
    isProgressTrackingEnabled,
    onClose,
    progressHistory,
    progressValue,
    floorplan,
    selectedDate,
    projectId,
  } = props;

  const history = useHistory();

  const chartPoints = useMemo<ChartPoint[]>(() => {
    if (
      !isProgressTrackingEnabled ||
      !progressHistory?.progress_values ||
      progressHistory.progress_values.length === 0
    ) {
      return [];
    }

    const result: ChartPoint[] = [];
    for (const entry of progressHistory.progress_values) {
      result.push({
        date: new Date(`${entry.date} 12:00:00`).getTime(),
        value: entry.value,
      });
    }

    return result;
  }, [isProgressTrackingEnabled, progressHistory?.progress_values]);

  const areDatesVisible = isProgressTrackingEnabled && Boolean(isOpen);

  const completionDate =
    areDatesVisible && progressHistory?.baseline_completion_date
      ? new Date(`${progressHistory.baseline_completion_date} 12:00:00`)
      : undefined;

  const startDate =
    areDatesVisible && progressHistory?.start_date ? new Date(`${progressHistory.start_date} 12:00:00`) : undefined;

  const mostRecentWalkthroughId = maxBy(floorplan?.dated_walkthroughs, 'when')?.id;
  const target = floorplan ? generateProjectPageUrl(projectId, floorplan.id, mostRecentWalkthroughId) : null;

  const bodyContent = (
    <>
      <Box>
        <EstimatedProgress
          progress={isProgressTrackingEnabled ? getRoundedProgressValue(progressValue ?? 0) : null}
          progressBarSize="lg"
        />
        <StatGroup marginTop="1.5rem">
          <PercentChangeStat value={isProgressTrackingEnabled ? progressHistory?.percent_change ?? null : null} />
          <DurationStat daysElapsed={isProgressTrackingEnabled ? progressHistory?.days_elapsed : undefined} />
        </StatGroup>
        <FloorplanBaselineDatesContainer
          completionDate={completionDate ?? null}
          startDate={startDate ?? null}
          canEditDates={canEditDates}
          projectId={projectId}
          floorplanId={floorplan?.id ?? -1}
        />
      </Box>
      <Flex
        borderBottom={`1px solid ${theme.colors.brand.gray[200]}`}
        marginBlockStart="1rem"
        paddingBottom="1.5rem"
        direction="column"
        alignItems="center"
      >
        <ProgressChart
          baselineCompletionDate={completionDate?.getTime()}
          baselineStartDate={startDate?.getTime()}
          data={chartPoints}
          hasMomentum={hasMomentum}
          highlightMode="duration"
          highlightInterval={14}
          highlightLimitMaxDate={selectedDate}
          minDurationToHighlight={14}
          minHeight="15rem"
          noDataMessage="📈 Data coming soon!"
        />
        {typeof hasMomentum === 'boolean' && (
          <MomentumReason hasMomentum={hasMomentum} hasCompletionDate={Boolean(completionDate)} />
        )}
      </Flex>
    </>
  );

  return (
    <ChakraDrawer isOpen={Boolean(isOpen)} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton>
          <Icon aria-hidden as={DoubleRightArrowIcon} height="1.5rem" width="1.5rem" />
        </DrawerCloseButton>
        <DrawerHeader>
          <Flex gap="1rem" alignItems="center" justifyContent="space-between">
            <Heading as="h1" size="lg">
              {floorplan?.name}
            </Heading>
            <Button
              onClick={() => (target ? history.push(target) : undefined)}
              data-pendo-label="Click view 360 from floorplan progress drawer"
              data-pendo-topic={PendoTopic.PROGRESS_TRACKING}
              leftIcon={<Icon aria-hidden as={Degree360Icon} height="1.25rem" width="1.25rem" />}
              variant="mediumEmphasisV2"
              flex="0 0 auto"
              size="sm"
              marginLeft="auto"
            >
              View 360
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isFetching && <LoadingIndicator />}
          {isError && !isFetching && (
            <Box margin="0 1rem">
              <Text>
                An error occurred. Please try again later. If this issue persists, contact{' '}
                <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
              </Text>
            </Box>
          )}
          {!isFetching && !isError && <>{bodyContent}</>}
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};
